import { Box } from "@mui/system";
import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import NoEncryptionGmailerrorredOutlinedIcon from "@mui/icons-material/NoEncryptionGmailerrorredOutlined";
import secureLocalStorage from "react-secure-storage";
import useAuthentication from "../../hooks/useAuthentication";
import loginBg from "../../images/loginPage/loginBg.jpg";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "./AdminLogin.css";
import Logo from "../../images/logo/logo.png";

const AdminLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const rememberUser = secureLocalStorage.getItem("remember");
  const [loginData, setLoginData] = useState({
    email: rememberUser?.email || "",
    password: rememberUser?.password || "",
  });


  const { isLoading, error, adminLogin } = useAuthentication();

  

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
    adminLogin(loginData, location, navigate);
    // e.target.reset();
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          display={{ xs: "none", sm: "none", md: "block" }}
          md={6}
          width="100%"
        >
          <Box className="bg-images">
            <Box
              sx={{
                height: "60px",
                // width: "180px",
                display: "flex",
                alignItems: "center",
                p: 2,
              }}
            >
              <img
                src={Logo}
                alt="logo"
                style={{
                  width: "200px",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            className="login-box"
            sx={{
              height: "100vh",
              padding: { md: "0px", sm: "0px 10px", xs: "0px 10px" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { md: "350px" },
                height: "fit-content",
                background: "var(--white)",
                padding: "0px",
                borderRadius: "20px",
              }}
            >
              <form onSubmit={handleSubmit}>
                <Box
                  my={2}
                  sx={{
                    width: "100%",
                    height: "60px",
                    background: "var(--primary-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    borderRadius: "20px 20px 0px 0px",
                    position: "relative",
                    marginTop: "0px",
                  }}
                >
                  <GppGoodOutlinedIcon
                    sx={{
                      position: "absolute",
                      top: "-10%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      background: "white",
                      color: "var(--primary-color)",
                      padding: "10px",
                      fontSize: "50px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography
                    style={{
                      color: "var(--secondary-color)",
                      fontSize: "20px",
                      fontWeight: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    Admin Login
                  </Typography>
                </Box>
                <Grid container spacing={2} sx={{ padding: "0px 20px 20px" }}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography
                      style={{
                        color: "var(--secondary-color)",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      Sign In to continue TicketFactory Admin Panel
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box sx={{ position: "relative" }}>
                      <input
                        placeholder="Enter Your Email ID"
                        value={loginData.email}
                        name="email"
                        type="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        onChange={handleOnChange}
                        style={{
                          height: "40px",
                          width: "calc(100% - 20px)",
                          outline: "none",
                          border: "1px solid black",
                          borderRadius: "20px",
                          padding: "0px 10px",
                          color: "var(--secondary-color)",
                          background: "transparent",
                          fontSize: "16px",
                        }}
                      />
                      <EmailOutlinedIcon
                        sx={{
                          color: "var(--primary-color)",
                          position: "absolute",
                          top: "50%",
                          right: "5px",
                          transform: "translate(-5px,-50%)",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box sx={{ position: "relative" }}>
                      <input
                        placeholder="Enter Your Password"
                        required
                        name="password"
                        value={loginData.password}
                        type={showPassword ? "text" : "password"}
                        onChange={handleOnChange}
                        style={{
                          height: "40px",
                          width: "calc(100% - 20px)",
                          outline: "none",
                          border: "1px solid black",
                          borderRadius: "20px",
                          padding: "0px 10px",
                          color: "var(--secondary-color)",
                          background: "transparent",
                          fontSize: "16px",
                        }}
                      />
                      {showPassword ? (
                        <LockOutlinedIcon
                          onClick={handleClickShowPassword}
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      ) : (
                        <NoEncryptionGmailerrorredOutlinedIcon
                          onClick={handleClickShowPassword}
                          sx={{
                            color: "var(--primary-color)",
                            position: "absolute",
                            top: "50%",
                            right: "5px",
                            transform: "translate(-5px,-50%)",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <Box>
                      {error && (
                        <Alert
                          severity="error"
                          style={{
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <AlertTitle color="red" m={0} p={0}>
                            Error !
                          </AlertTitle>{" "}
                          <strong textAlign={"center"}>{error} !</strong>
                        </Alert>
                      )}
                    </Box>
                    <Box>
                      <NavLink
                        style={{ textDecoration: "none" }}
                        to="/resetpassword"
                      >
                        <Typography
                          sx={{
                            color: "var(--black)",
                            fontSize: "14px",
                            width: "100%",
                            textAlign: "right",
                          }}
                        >
                          Forget Password ?
                        </Typography>
                      </NavLink>
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Button
                      type="submit"
                      disabled={!isLoading ? true : false}
                      sx={{
                        width: "100%",
                        borderRadius: "20px",
                        background: "var(--primary-color)",
                        color: "var(--white)",
                        "&:hover": {
                          background: "var(--primary-color)",
                          color: "var(--white)",
                        },
                      }}
                    >
                      {isLoading ? (
                        `Sign In →`
                      ) : (
                        <CircularProgress
                          style={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminLogin;
