import {
  Box,
  Container,
  createTheme,
  Grid,
  Pagination,
  Stack,
  Tab,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MarkUpDetails.css";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { AdminHeader } from "../../AdminHeader/AdminHeader";
import Success from "../../../images/undraw/undraw_completed_tasks_vs6q.svg";
import ServerDown from "../../../images/undraw/undraw_server_down_s-4-lk.svg";
import ReConfirm from "../../../images/undraw/undraw_confirmation_re_b6q5.svg";
import Loader from "../../../images/loader/Render.gif";

import Swal from "sweetalert2";
import AgentMarkup from "../../Admin/AgentManagement/AgentMarkup";
import AirlineMarkup from "../../AirlineMarkup/AirlineMarkup";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const MarkUpDetails = () => {
  const navigate = useNavigate();
  const [markupData, setMarupData] = useState({});
  const [value, setValue] = useState("api");
  const [isLoading, setIsLoading] = useState(true);
  const [markUpData, setMarkUpData] = useState();
  const [airlineData, setAirlineUpData] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleMarkupData = (e) => {
    setMarupData({ ...markupData, [e.target.name]: e.target.value });
  };

  // Handle a page change.
  const agentId = "";

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://api.flyjatt.com/v1/MarkUp/index.php?${value}`;
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        markuptype: markupData?.markupType,
        markup: markupData?.amount,
      }),
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          Swal.fire({
            icon: "success",
            title: data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            navigate(0);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            navigate(0);
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          icon: "error",
          title: "Markup Failed!",
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          navigate(0);
        });
      });
  };

  useEffect(() => {
    const url = "https://api.flyjatt.com/v1/AirMaterials/Airlines.php?all";
    const url1 = "https://api.flyjatt.com/v1/MarkUp/index.php?all";
    fetch(url1)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setMarkUpData(data?.data[0]);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Failed! to Fetch",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Another Time",
        }).then(() => {
          setIsLoading(true);
          navigate("/admin/dashboard");
        });
      });
    // fetch(url)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setIsLoading(false);
    //     setAirlineUpData(data?.data[0]);
    //     console.log("allmarkup", data?.data[0]);
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //     setIsLoading(false);
    //     Swal.fire({
    //       imageUrl: ServerDown,
    //       imageWidth: 400,
    //       imageHeight: 200,
    //       imageAlt: "Custom image",
    //       title: "Failed! to Fetch",
    //       confirmButtonColor: "var(--primary-color)",
    //       confirmButtonText: "Try Another Time",
    //     }).then(() => {
    //       setIsLoading(true);
    //       navigate("/admin/dashboard");
    //     });
    //   });
  }, []);

  if (isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
          Loading ...
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box>
          {/* <AdminHeader /> */}
          <Typography
            my={2}
            style={{
              color: "var(--mateBlack)",
              fontSize: "22px",
              fontWeight: "500",
            }}
          >
            Add Markup
          </Typography>
          <Box>
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  height: { md: "100%", sm: "100%", xs: "100%" },
                  display: "flex",
                  justifyContent: {
                    md: "space-between",
                    sm: "start",
                    xs: "start",
                  },
                  alignItems: "center",
                  opacity: "1",
                  "& .MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                    gap: "5px",
                  },
                  ".MuiTabs-root": {
                    minHeight: "fit-content",
                  },
                  "& button": {
                    opacity: "1",
                    background: "var(--third-color)",
                    color: "var(--secondary-color)",
                    width: "fit-content",
                    minHeight: "fit-content",
                    padding: "10px 5px",
                    fontSize: "12px",
                    borderRadius: "4px",
                  },
                  "& button.Mui-selected,& button.Mui-selected >svg": {
                    background: "var(--secondary-color)",
                    color: "var(--white) !important",
                  },
                  "& .MuiBadge-badge": {
                    fontSize: "10px",
                    right: 6,
                    top: 2,
                  },
                }}
              >
                <TabList
                  onChange={handleChange}
                  color="#fff"
                  indicatorColor="none"
                >
                  <Tab label="Api" value="api" />
                  <Tab label="Airlines" value="airlines" />
                  {/* <Tab label="Airlines" value="airlines" /> */}
                  <Tab label="Refund" value="refund" />
                  <Tab label="Reissue" value="reissue" />
                  <Tab label="Void" value="void" />
                </TabList>
              </Box>
              {/* //todo:transaction part */}
              <TabPanel value="api" style={{ padding: "10px 0" }}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                      <select
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        name="markupType"
                        value={markupData?.markupType || ""}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ""}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          background: "var(--secondary-color)",
                          color: "white",
                          outline: "none",
                          padding: "5px 10px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          border: "1px solid var(--secondary-color)",
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
              <TabPanel value="airlines" style={{ padding: "10px 0" }}>
                {/* <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                      <select
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        name="markupType"
                        value={markupData?.markupType || ""}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ""}
                        type="number"
                        placeholder="Amount"
                        required
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          background: "var(--secondary-color)",
                          color: "white",
                          outline: "none",
                          padding: "5px 10px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          border: "1px solid var(--secondary-color)",
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form> */}
                <AirlineMarkup />
              </TabPanel>
              <TabPanel value="flight" style={{ padding: "10px 0" }}>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4}>
                      <select
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        name="markupType"
                        value={markupData?.markupType || ""}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ""}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          background: "var(--secondary-color)",
                          color: "white",
                          outline: "none",
                          padding: "5px 10px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          border: "1px solid var(--secondary-color)",
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>

              <TabPanel value="refund" style={{ padding: "10px 0" }}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      "& input": {
                        width: "100%",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <select
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        name="markupType"
                        value={markupData?.markupType || ""}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ""}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          background: "var(--secondary-color)",
                          color: "white",
                          outline: "none",
                          padding: "5px 10px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          border: "1px solid var(--secondary-color)",
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
              <TabPanel value="reissue" style={{ padding: "10px 0" }}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      "& input": {
                        width: "100%",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <select
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        name="markupType"
                        value={markupData?.markupType || ""}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ""}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          background: "var(--secondary-color)",
                          color: "white",
                          outline: "none",
                          padding: "5px 10px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          border: "1px solid var(--secondary-color)",
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
              <TabPanel value="void" style={{ padding: "10px 0" }}>
                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      "& input": {
                        width: "100%",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      },
                    }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <select
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        name="markupType"
                        value={markupData?.markupType || ""}
                        onChange={handleMarkupData}
                      >
                        <option value="">Markup Type</option>
                        <option value="amount">Amount</option>
                        <option value="percent">Percent(%)</option>
                      </select>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <input
                        name="amount"
                        value={markupData?.amount || ""}
                        type="text"
                        placeholder="Amount"
                        required
                        style={{
                          width: "100%",
                          padding: "5px 10px",
                          outline: "none",
                          border: "1px solid var(--secondary-color)",
                          borderRadius: "4px !important",
                        }}
                        onChange={handleMarkupData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <button
                        type="submit"
                        style={{
                          width: "100%",
                          background: "var(--secondary-color)",
                          color: "white",
                          outline: "none",
                          padding: "5px 10px",
                          borderRadius: "4px",
                          fontSize: "14px",
                          border: "1px solid var(--secondary-color)",
                        }}
                      >
                        Submit
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
        {/* <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{
                color: "var(--mateBlack)",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Markup
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                mt: "5px",
                "&::-webkit-scrollbar": {
                  width: "5px",
                  height: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "0px",
                  background: "var(--secondary-color)",
                },
                table: {
                  width: "50%",
                  position: "relative",
                  borderCollapse: "collapse",
                },
                th: {
                  position: "sticky",
                  top: 0,
                  zIndex: "999",
                  background: "var(--primary-color)",
                  color: "white",
                  border: "1px solid #ddd",
                  padding: "8px 12px",
                  fontSize: "14px",
                  fontWeight: "500",
                },
                td: {
                  textAlign: "center",
                  verticalAlign: "middle",
                  border: "1px solid #ddd !important",
                  padding: "5px !important",
                  fontSize: "12px",
                },
                "tr:nth-child(even)": {
                  background: "#f2f2f2",
                },
                "tr:hover": {
                  background: "#ddd",
                },
              }}
            >
              <table>
                {value === "void" ? (
                  <tr>
                    <th>VoidMarkUpType</th>
                    <th>VoidMarkUpAmount</th>
                  </tr>
                ) : value === "refund" ? (
                  <tr>
                    <th>RefundMarkUpType</th>
                    <th>RefundMarkUpAmount</th>
                  </tr>
                ) : value === "reissue" ? (
                  <tr>
                    <th>ReIssueMarkUpType</th>
                    <th>ReIssueMarkUpAmount</th>
                  </tr>
                ) : value === "api" ? (
                  <tr>
                    <th>Api Markup Type</th>
                    <th>Api Markup Amount</th>
                  </tr>
                ) : value === "airlines" ? (
                  <tr>
                    <th>Airlines Markup Type</th>
                    <th>Airlines Markup Amount</th>
                  </tr>
                ) : (
                  <tr>
                    <th>FlightMarkUpType</th>
                    <th>FlightMarkUpAmount</th>
                  </tr>
                )}

                <tbody>
                  {value === "void" ? (
                    <tr>
                      <td>{markUpData?.voidmarkuptype || "N/A"}</td>
                      <td>{markUpData?.voidmarkup || "N/A"}</td>
                    </tr>
                  ) : value === "refund" ? (
                    <tr>
                      <td>{markUpData?.refundmarkuptype || "N/A"}</td>
                      <td>{markUpData?.refundmarkup || "N/A"}</td>
                    </tr>
                  ) : value === "reissue" ? (
                    <tr>
                      <td>{markUpData?.reissuemarkuptype || "N/A"}</td>
                      <td>{markUpData?.reissuemarkup || "N/A"}</td>
                    </tr>
                  ) : value === "api" ? (
                    <tr>
                      <td>{markUpData?.markuptype || "N/A"}</td>
                      <td>{markUpData?.markup || "N/A"}</td>
                    </tr>
                  ) : value === "airlines" ? (
                    <tr>
                      <td>{markUpData?.reissuemarkuptype || "N/A"}</td>
                      <td>{markUpData?.reissuemarkup || "N/A"}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td>{markUpData?.flightmarkuptype || "N/A"}</td>
                      <td>{markUpData?.flightmarkup || "N/A"}</td>
                    </tr>
                  )}
                  <tr></tr>
                </tbody>
              </table>
            </Box>
          </Box>
        </Box> */}
      </Container>
    </Box>
  );
};

export default MarkUpDetails;
