import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFPageDesign from "./PDFPageDesign";
import { Stack } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

const AllPDF = ({
  isLoading,
  bookingId,
  flightData,
  fareTotal,
  passengerData,
  allData,
  tripType,
  stateData,
  qeuesData,
}) => {
  // let bookingwp = `Booking with price ${flightData[0]?.fromAirportCode}-${
  //   flightData[flightData.length - 1]?.toAirportCode
  // } ${flightData[0]?.departureDate} ${tripType || ""}`;
  // let bookingwop = `Booking without price ${flightData[0]?.fromAirportCode}-${
  //   flightData[flightData.length - 1]?.toAirportCode
  // } ${flightData[0]?.departureDate} ${tripType || ""}`;

  // let eticketp = `e-Ticket with price ${flightData[0]?.fromAirportCode}-${
  //   flightData[flightData.length - 1]?.toAirportCode
  // } ${flightData[0]?.departureDate} ${tripType || ""}`;
  // let eticketwop = `e-Ticket without price ${flightData[0]?.fromAirportCode}-${
  //   flightData[flightData.length - 1]?.toAirportCode
  // } ${flightData[0]?.departureDate} ${tripType || ""}`;

  let bookingwp = `Booking with price`;
  let bookingwop = `Booking without price`;

  let eticketp = `e-Ticket with price`;
  let eticketwop = `e-Ticket without price`;

  return (
    <div>
      {stateData?.Status === "Hold" ||
      stateData?.Status === "Cancelled" ||
      stateData?.Status === "Issue In Processing" ? (
        <Stack direction="row" justifyContent="space-between">
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Booking Copy"
                check="1"
                tripType={tripType}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={bookingwp}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: "#fff",
                    background: "#222222",
                    cursor: "pointer",
                    textAlign: "center",
                    textDecoration: "none",
                    fontSize: 12,
                    padding: "8px",
                    borderRadius: "5px",
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> With Price
                </a>
              )
            }
          </PDFDownloadLink>
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Booking Copy"
                check="2"
                tripType={tripType}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={bookingwop}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: "#fff",
                    background: "#222222",
                    cursor: "pointer",
                    textAlign: "center",
                    textDecoration: "none",
                    fontSize: 12,
                    padding: "8px",
                    borderRadius: "5px",
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> Without Price
                </a>
              )
            }
          </PDFDownloadLink>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between">
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="e - Ticket"
                check="1"
                tripType={tripType}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={eticketp}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: "#fff",
                    background: "#222222",
                    cursor: "pointer",
                    textAlign: "center",
                    textDecoration: "none",
                    fontSize: 12,
                    padding: "8px",
                    borderRadius: "5px",
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> With Price
                </a>
              )
            }
          </PDFDownloadLink>
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="e - Ticket"
                check="2"
                tripType={tripType}
                bookingId={bookingId}
                flightData={flightData}
                fareTotal={fareTotal}
                passengerData={passengerData}
                allData={allData}
                stateData={stateData}
                qeuesData={qeuesData}
              />
            }
            fileName={eticketwop}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={fileName}
                  target="_blank"
                  style={{
                    color: "#fff",
                    background: "#222222",
                    cursor: "pointer",
                    textAlign: "center",
                    textDecoration: "none",
                    fontSize: 12,
                    padding: "8px",
                    borderRadius: "5px",
                  }}
                  rel="noreferrer"
                >
                  <PrintIcon sx={{ fontSize: 12 }} /> Without Price
                </a>
              )
            }
          </PDFDownloadLink>
        </Stack>
      )}
    </div>
  );
};

export default AllPDF;
