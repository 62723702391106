import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./components/Contexts/AuthProvider";
import BookingRoute from "./components/Admin/BookingManagement/BookingRoute";
import GeneralLedgerRoute from "./pages/General Ledger/GeneralLedgerRoute";
import SearchCountParent from "./components/SearchCountComponent/SearchCountParent/SearchCountParent";
import Admin from "./pages/Admin/Admin";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import NotFound from "./components/NotFound/NotFound";
import AdminLogin from "./components/AdminLogin/AdminLogin";
import AdminAddBank from "./components/Admin/AdminBank/AdminAddBank";
import { Box } from "@mui/material";
import "./App.css";
import AdminDashboardSideBar from "./components/AdminDashboardSideBar/AdminDashboardSideBar";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import Markup from "./components/Markup/FlightMarkUp/Markup";
import MarkUpDetails from "./components/MarkUpDetails/FlightMarkUpDetails/MarkUpDetails";
import AdminTransectionReport from "./components/AdminReport/AdminTransectionReport";
import AdminLedgerReport from "./components/AdminReport/AdminLedgerReport";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LiveDashboard from "./components/Dashboard/LiveDashboard/LiveDashboard";
import PortalControl from "./components/Admin/ControlApi/PortalControl/PortalControl";
import AdminBankAccount from "./components/Admin/AdminBank/AdminBankAccount";
import AgentManagement from "./components/Admin/AgentManagement/AgentManagement";
import AdminAccount from "./components/Admin/AdminAccount/AdminAccount";
import AdminAddStaff from "./components/Admin/AdminAccount/AdminAddStaff";
import PaymentManagement from "./components/Admin/PaymentManagement/PaymentManagement";
import Queuedetails from "./components/Queuedetails/Queuedetails";
import AgentDetails from "./components/Admin/AgentManagement/AgentDetails";
import { QueryClient, QueryClientProvider } from "react-query";
import AdminNotice from "./components/AdminNotice/AdminNotice";
import AddNotice from "./components/AdminNotice/AddNotice";
import AgentSearch from "./components/Admin/AgentManagement/SingleAgentData/AgentSearch";
import AgentBooking from "./components/Admin/AgentManagement/SingleAgentData/AgentBooking";
import AgentDeposit from "./components/Admin/AgentManagement/SingleAgentData/AgentDeposit";
import AgetnLadger from "./components/Admin/AgentManagement/SingleAgentData/AgetnLadger";
import AirlineMarkup from "./components/AirlineMarkup/AirlineMarkup";
import AllVisa from "./components/AllVisa/AllVisa";
import AllLicense from "./components/AllLicense/AllLicense";
import Offer from "./components/Admin/Manage/Offer";
import HotDeal from "./components/Admin/Manage/HotDeal";
import DetailsLicense from "./components/AllLicense/DetailsLicense";
import secureLocalStorage from "react-secure-storage";
import DetailsVisa from "./components/AllVisa/DetailsVisa";
import Swal from "sweetalert2";
import SessionTimeout from "./components/SessionTimeOut/SessionTimeOut";

const queryClient = new QueryClient();

function App() {
  // const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(true);
  const [isStaff, setIsStaff] = useState(true);
  const user = secureLocalStorage.getItem("admin-info");
  const admin = user?.data?.role === "Admin";
  const staff = user?.data?.role === "Staff";
  const accountant = user?.data?.role === "Accountant";
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          {/* //todo set Session time out here it set 5 min 5*60*1000=300000 */}
          <SessionTimeout sessionTimeout={300000} />
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<AdminLogin />} />
            {staff && (
              <Route
                path="/admin"
                element={
                  <PrivateRoute>
                    <AdminDashboardSideBar />
                  </PrivateRoute>
                }
              >
                <Route path="/admin/dashboard" element={<LiveDashboard />} />
                <Route path="/admin/license" element={<AllLicense />} />
                <Route
                  path="/admin/details-license"
                  element={<DetailsLicense />}
                />
                <Route path="/admin/visa" element={<AllVisa />} />
                <Route path="/admin/details-visa" element={<DetailsVisa />} />

                <Route path="/admin/agentbooking" element={<AgentBooking />} />

                <Route path="/admin/bookingroute" element={<BookingRoute />} />

                <Route path="/admin/queuedetails" element={<Queuedetails />} />

                <Route
                  path="/admin/bookingManagement"
                  element={<BookingRoute />}
                />
              </Route>
            )}
            {accountant && (
              <Route
                path="/admin"
                element={
                  <PrivateRoute>
                    <AdminDashboardSideBar />
                  </PrivateRoute>
                }
              >
                <Route path="/admin/dashboard" element={<LiveDashboard />} />

                <Route path="/admin/license" element={<AllLicense />} />
                <Route
                  path="/admin/details-license"
                  element={<DetailsLicense />}
                />
                <Route path="/admin/visa" element={<AllVisa />} />
                <Route path="/admin/details-visa" element={<DetailsVisa />} />

                <Route path="/admin/agentbooking" element={<AgentBooking />} />
                <Route path="/admin/agentdeposit" element={<AgentDeposit />} />

                <Route path="/admin/agentdetails" element={<AgentDetails />} />
                <Route
                  path="/admin/paymentManagement"
                  element={<PaymentManagement />}
                />
                <Route path="/admin/bookingroute" element={<BookingRoute />} />

                <Route path="/admin/addbank" element={<AdminAddBank />} />
                <Route path="/admin/allbank" element={<AdminBankAccount />} />

                <Route path="/admin/queuedetails" element={<Queuedetails />} />

                <Route
                  path="/admin/bookingManagement"
                  element={<BookingRoute />}
                />
              </Route>
            )}
            {admin && (
              <Route
                path="/admin"
                element={
                  <PrivateRoute>
                    <AdminDashboardSideBar />
                  </PrivateRoute>
                }
              >
                <Route path="/admin/dashboard" element={<LiveDashboard />} />
                <Route
                  path="/admin/searchcount"
                  element={<SearchCountParent />}
                />

                <Route path="/admin/notice" element={<AdminNotice />} />
                <Route path="/admin/offer" element={<Offer />} />
                <Route path="/admin/hotdeal" element={<HotDeal />} />
                <Route path="/admin/license" element={<AllLicense />} />
                <Route
                  path="/admin/details-license"
                  element={<DetailsLicense />}
                />
                <Route path="/admin/visa" element={<AllVisa />} />
                <Route path="/admin/details-visa" element={<DetailsVisa />} />

                <Route
                  path="/admin/airline-markup"
                  element={<AirlineMarkup />}
                />

                <Route path="/admin/addnotice" element={<AddNotice />} />
                <Route
                  path="/admin/portalcontrol"
                  element={<PortalControl />}
                />
                <Route path="/admin/controlpanel" element={<ControlPanel />} />

                <Route
                  path="/admin/markupdetails"
                  element={<MarkUpDetails />}
                />
                <Route path="/admin/markup" element={<Markup />} />

                <Route path="/admin/agentbooking" element={<AgentBooking />} />
                <Route path="/admin/agentdeposit" element={<AgentDeposit />} />
                <Route path="/admin/agentladger" element={<AgetnLadger />} />
                <Route path="/admin/agentsearch" element={<AgentSearch />} />
                <Route
                  path="/admin/agentmanagement"
                  element={<AgentManagement />}
                />
                <Route path="/admin/agentdetails" element={<AgentDetails />} />
                <Route
                  path="/admin/paymentManagement"
                  element={<PaymentManagement />}
                />
                <Route path="/admin/bookingroute" element={<BookingRoute />} />
                <Route path="/admin/account" element={<AdminAccount />} />
                <Route path="/admin/addstaff" element={<AdminAddStaff />} />

                <Route path="/admin/addbank" element={<AdminAddBank />} />
                <Route path="/admin/allbank" element={<AdminBankAccount />} />

                <Route
                  path="/admin/transection"
                  element={<AdminTransectionReport />}
                />
                <Route path="/admin/queuedetails" element={<Queuedetails />} />

                <Route path="/admin/ledger" element={<AdminLedgerReport />} />
                <Route
                  path="/admin/bookingManagement"
                  element={<BookingRoute />}
                />
                <Route
                  path="/admin/searchManagement"
                  element={<SearchCountParent />}
                />
                <Route
                  path="/admin/generalledgerroute"
                  element={<GeneralLedgerRoute />}
                />
              </Route>
            )}

            {/*  //todo:search management section*/}

            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
