import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import PeopleIcon from "@mui/icons-material/People";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LanguageIcon from "@mui/icons-material/Language";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { AdminHeader } from "../../AdminHeader/AdminHeader";
import "./LiveDashboard.css";
import secureLocalStorage from "react-secure-storage";

const LiveDashboard = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem("admin-info");
  let agentId = users?.user?.agentId;
  const [isLoading, setIsLoading] = useState(false);
  const [queuesData, setQueuesData] = useState([]);
  const [allQueuesData, setAllQueuesData] = useState([]);
  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    const url = `https://api.flyjatt.com/v1/Stats/dashboard.php`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.number_of_page);
        setQueuesData(data?.TotalBookingData);
        setAllQueuesData(data);
        setIsLoading(true);
      });
    //todo: end of fetch all data
  }, [agentId]);
  const sendToQueuesDetails = (bookingDetail) => {
    navigate("/admin/queuedetails", {
      state: {
        data: bookingDetail,
        path: "queues",
      },
    });
  };
  return (
    <Box className="dashboard-live">
      <AdminHeader />
      <Container>
        {/* -------------------------- */}
        <Grid
          container
          spacing={0}
          sx={{ overflowY: "hidden", height: "40vh" }}
        >
          <Grid item display={{ xs: "none", sm: "none", md: "block" }} md={0.4}>
            <Box
              sx={{
                background: "var(--secondary-color)",
                borderRadius: "3px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "space-evenly",
                a: {
                  textDecoration: "none",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
            >
              <NavLink>
                <AirplanemodeActiveIcon />
              </NavLink>
              <NavLink>
                <PeopleIcon />
              </NavLink>
              <NavLink>
                <TravelExploreIcon />
              </NavLink>
              <NavLink>
                <DarkModeIcon />
              </NavLink>
              <NavLink>
                <PersonSearchIcon />
              </NavLink>
              <NavLink>
                <LanguageIcon />
              </NavLink>
            </Box>
          </Grid>
          <Grid item xs={12} md={9.1}>
            <Typography sx={{ fontSize: 16, fontWeight: 500, pl: 2 }}>
              Air Ticket Live Booking
            </Typography>
            <Box
              sx={{
                overflowY: "auto",
                height: "38vh",
                table: {
                  borderCollapse: "collapse",
                  width: "100%",
                },
                th: {
                  border: "none",
                  padding: "5px 8px",
                  textAlign: "center",
                  background: "var(--primary-color)",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 400,
                },
                td: {
                  border: "none",
                  padding: "5px",
                  fontSize: "12px",
                },
                "tr:nth-child(even)": {
                  background: "#f2f2f2",
                },
                "tr:hover": { background: "#ddd" },
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>BookingId</th>
                    <th>Status</th>
                    <th>BookedAt</th>
                    <th>Passenger</th>
                    <th>Route</th>
                    <th>Type</th>
                    {/* <th>TravelDate</th> */}
                    <th>Airline</th>
                  </tr>
                </thead>

                <tbody>
                  {queuesData?.map((bookingDetail, index) => (
                    <tr key={index}>
                      <td>
                        <button
                          style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer",
                            backgroundColor: "#d1e9ff",
                            padding: "5px 15px",
                            color: "#003566",
                            textDecoration: "underline",
                          }}
                          onClick={() => sendToQueuesDetails(bookingDetail)}
                        >
                          {bookingDetail.BookingId ?? "Reference No"}
                        </button>
                      </td>
                      <td>{bookingDetail.Status}</td>
                      <td>
                        {new Date(bookingDetail?.created_at).toLocaleString(
                          "en-GB",
                          {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: false,
                          }
                        )}
                        {/* {bookingDetail?.created_at !== "" || "undefined" || null
                          ? format(
                              new Date(bookingDetail?.created_at),
                              "dd MMM yy hh:mm a"
                            )
                          : "Booked Date"} */}
                      </td>
                      <td>{bookingDetail?.Name || "Passenger Name"}</td>
                      <td>
                        {bookingDetail.DepFrom ?? "From"} -{" "}
                        {bookingDetail.ArrTo ?? "To"}
                      </td>
                      <td>{bookingDetail.TripType ?? "Flight Type"}</td>

                      {/* <td>{bookingDetail?.journeyDate || "Flight Date"}</td> */}

                      <td>{bookingDetail.Career ?? "Airline"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2.5}
            sx={{
              overflowY: "hidden",
              height: { xs: "40%", sm: "40vh" },
              pl: 2,
            }}
          >
            <Box
              style={{
                background: "#D1E9FF",
                padding: "5px 10px",
                borderRadius: "3px",
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Total Booking
              </Typography>
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "25px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.Hold || 0}
              </Typography>
            </Box>

            <Box
              sx={{
                background: "#F7D800",
                padding: "5px 10px",
                borderRadius: "3px",
                mt: 1,
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Total Ticketed
              </Typography>
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "25px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.Ticketed || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#525371",
                padding: "5px 10px",
                borderRadius: "3px",
                my: 1,
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Void
              </Typography>
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "25px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.Void || 0}
              </Typography>
            </Box>
            <Box
              style={{
                background: "crimson",
                padding: "5px 10px",
                borderRadius: "3px",
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Total Cancelled
              </Typography>
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "25px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.Cancelled || 0}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* --------------------------------- */}

        <Grid
          container
          spacing={2}
          my={2}
          sx={{ overflowY: "hidden", height: "50vh" }}
        >
          <Grid
            item
            xs={12}
            sm={2.5}
            sx={{ overflowY: "hidden", height: "50vh" }}
          >
            <Box
              style={{
                background: "#D1E9FF",
                padding: "5px 10px",
                borderRadius: "3px",
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Total Deposit
              </Typography>
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "25px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalDeposit || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#F7D800",
                padding: "5px 10px",
                borderRadius: "3px",
                my: 1,
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Total Deposit Pending
              </Typography>
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "25px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalDepositPending || 0}
              </Typography>
            </Box>
            <Box
              sx={{
                background: "#525371",
                padding: "5px 10px",
                borderRadius: "3px",
                mb: 1,
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Total Deposit Approved
              </Typography>
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "25px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalDepositApproved || 0}
              </Typography>
            </Box>
            <Box
              style={{
                background: "crimson",
                padding: "5px 10px",
                borderRadius: "3px",
              }}
            >
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
              >
                Total Deposit Rejected
              </Typography>
              <Typography
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "25px",
                  textAlign: "right",
                  fontWeight: 500,
                }}
              >
                {allQueuesData?.TotalDepositRejected || 0}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9.5}>
            <Grid container spacing={2}>
              <Grid item md={7}>
                <Typography sx={{ fontSize: 16, fontWeight: 500, pl: 2 }}>
                  Current Deposit
                </Typography>
                <Box
                  sx={{
                    overflowY: "auto",
                    height: "38vh",
                    table: {
                      borderCollapse: "collapse",
                      width: "100%",
                    },
                    th: {
                      border: "none",
                      padding: "5px 8px",
                      textAlign: "center",
                      background: "var(--primary-color)",
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: 400,
                    },
                    td: {
                      border: "none",
                      padding: "5px",
                      fontSize: "12px",
                    },
                    "tr:nth-child(even)": {
                      background: "#f2f2f2",
                    },
                    "tr:hover": { background: "#ddd" },
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Deposit&nbsp;Id</th>
                        <th>Status</th>
                        <th>Sender</th>
                        <th>Amount</th>
                        <th>Time</th>
                      </tr>
                    </thead>

                    <tbody>
                      {allQueuesData?.TotalDepositData?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <button
                              style={{
                                background: "transparent",
                                border: "none",
                                cursor: "pointer",
                                backgroundColor: "#d1e9ff",
                                padding: "5px 15px",
                                color: "#003566",
                              }}
                            >
                              {data.depositId ?? "Deposit Id"}
                            </button>
                          </td>
                          <td>{data.status}</td>
                          <td>{data?.sender}</td>
                          <td>{data?.amount}</td>
                          <td>
                            {new Date(data?.created_at).toLocaleString(
                              "en-uk",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: false,
                              }
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ fontSize: 16, fontWeight: 500, pl: 2 }}>
                  Live Search
                </Typography>
                <Box
                  sx={{
                    overflowY: "auto",
                    height: "38vh",
                    table: {
                      borderCollapse: "collapse",
                      width: "100%",
                    },
                    th: {
                      border: "none",
                      padding: "5px 8px",
                      textAlign: "center",
                      background: "var(--primary-color)",
                      color: "#fff",
                      fontSize: "14px",
                      fontWeight: 400,
                    },
                    td: {
                      border: "none",
                      padding: "5px",
                      fontSize: "12px",
                    },
                    "tr:nth-child(even)": {
                      background: "#f2f2f2",
                    },
                    "tr:hover": { background: "#ddd" },
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        {/* <th>Agent&nbsp;Id</th> */}
                        <th width="50%">Company</th>
                        <th width="30%">Route</th>
                        <th width="20%">TripType</th>
                      </tr>
                    </thead>

                    <tbody>
                      {allQueuesData?.SearchData?.map((data, index) => (
                        <tr>
                          {/* <td>{data?.agentId || "agentId"}</td> */}
                          <td>{data?.company || "Company Name"}</td>
                          <td>
                            {data.DepFrom ?? "From"} - {data.ArrTo ?? "To"}
                          </td>
                          <td>{data?.TripType}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LiveDashboard;
