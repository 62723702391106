import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdminHeader } from "../../../AdminHeader/AdminHeader";
import Loader from "../../../../images/loader/Render.gif";
import { format } from "date-fns";

const AgentSearch = () => {
  const location = useLocation();
  const agentId = location?.state;
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchHistory, setSearchHistory] = useState([]);
  useEffect(() => {
    fetch(
      `https://api.flyjatt.com/v1/SearchHistory/index.php?agentId=${agentId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoaded(true);
        setSearchHistory(data?.data);
      });
  }, [agentId]);
  if (!isLoaded) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "30%",
            height: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }
  return (
    <div>
      <AdminHeader />
      <Container>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: "var(--secondary-color)",
            fontSize: "24px",
            fontWeight: "500",
          }}
        >
          Flight Search Results
        </Typography>
        <Box
          sx={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "0px",
              background: "var(--secondary-color)",
            },
            height: "calc(100vh - 200px)",
            overflowY: "scroll",
            table: {
              width: "100%",
              position: "relative",
              borderCollapse: "collapse",
            },
            th: {
              position: "sticky",
              top: 0,
              zIndex: "999",
              background: "var(--primary-color)",
              color: "white",
              border: "1px solid #ddd",
              padding: "8px 12px",
              fontSize: "14px",
              fontWeight: "500",
            },
            td: {
              textAlign: "center",
              verticalAlign: "middle",
              border: "1px solid #ddd !important",
              padding: "5px !important",
              fontSize: "12px",
            },
            "tr:nth-child(even)": {
              background: "#f2f2f2",
            },
            "tr:hover": {
              background: "#ddd",
            },
          }}
        >
          <table>
            <thead>
              <tr>
                <th>Trip Type</th>
                <th>Departur</th>
                <th>Arrival</th>
                <th>Departur Date</th>
                <th>Arrival Date</th>
                <th>Search Date</th>
              </tr>
            </thead>
            <tbody>
              {searchHistory?.map((data, index) => (
                <tr key={{ index }}>
                  <td>{data?.TripType}</td>
                  <td>{data?.DepFrom}</td>
                  <td>{data?.ArrTo}</td>
                  <td>{data?.GoDate || "N/A"}</td>
                  <td>{data?.BackDate || "N/A"}</td>
                  {/* <td>{data?.created_at || "N/A"}</td> */}
                  <td>
                    {data?.actionAt !== ""
                      ? format(
                          new Date(data?.created_at),
                          "dd MMM yyyy hh:mm a"
                        )
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Container>
    </div>
  );
};

export default AgentSearch;
