import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import { AdminHeader } from "../../AdminHeader/AdminHeader";

const AdminAddStaff = () => {
  const navigate = useNavigate();
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [role, setRole] = useState("Admin");
  let body = JSON.stringify({
    fname: fName,
    lname: lName,
    email: email,
    password: password,
    phone: contact,
    role: role,
  });

  const handleAdd = (e) => {
    e.preventDefault();

    let url = `https://api.flyjatt.com/v1/Auth/admin.php?add`;
    let body = JSON.stringify({
      fname: fName,
      lname: lName,
      email: email,
      password: password,
      phone: contact,
      role: role,
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "success",
            text: "Added Successfully",
            confirmButtonText: "ok",
          }).then(function () {
            navigate(-1);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: `${data.message}`,
            confirmButtonText: "ok",
          });
        }
      });
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box mt={2}>
          <Typography
            sx={{ fontWeight: "500px", fontSize: "23px", color: "#003566" }}
          >
            Add Admin
          </Typography>

          <form onSubmit={handleAdd}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: "var(--primary-color)", fontSize: "14px" }}
                  >
                    First Name
                  </Typography>
                  <input
                    required
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: "1px solid var(--black)",
                      width: "100%",
                      outline: "none",
                    }}
                    placeholder="First Name"
                    onChange={(e) => setFname(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    style={{ color: "var(--primary-color)", fontSize: "14px" }}
                    mb={1}
                  >
                    Last Name
                  </Typography>
                  <input
                    required
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: "1px solid var(--black)",
                      width: "100%",
                      outline: "none",
                    }}
                    placeholder="Last Name"
                    onChange={(e) => setLname(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: "var(--primary-color)", fontSize: "14px" }}
                  >
                    Email
                  </Typography>
                  <input
                    required
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: "1px solid var(--black)",
                      width: "100%",
                      outline: "none",
                    }}
                    placeholder="Email Name"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: "var(--primary-color)", fontSize: "14px" }}
                  >
                    Password
                  </Typography>
                  <input
                    required
                    type="password"
                    style={{
                      border: "none",
                      borderBottom: "1px solid var(--black)",
                      width: "100%",
                      outline: "none",
                    }}
                    placeholder="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: "var(--primary-color)", fontSize: "14px" }}
                  >
                    Contact Number
                  </Typography>
                  <input
                    required
                    type="text"
                    style={{
                      border: "none",
                      borderBottom: "1px solid var(--black)",
                      width: "100%",
                      outline: "none",
                    }}
                    placeholder="Contact Number"
                    onChange={(e) => setContact(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={4}>
                <Box>
                  <Typography
                    mb={1}
                    style={{ color: "var(--primary-color)", fontSize: "14px" }}
                  >
                    Role
                  </Typography>

                  <select
                    onChange={(e) => setRole(e.target.value)}
                    style={{
                      border: "none",
                      borderBottom: "1px solid var(--black)",
                      width: "100%",
                      outline: "none",
                    }}
                  >
                    <option value={"Admin"}>Admin</option>
                    <option value={"Accountant"}>Accountant</option>
                    <option value={"Staff"}>Staff</option>
                  </select>
                </Box>
              </Grid>
            </Grid>

            <Button
              sx={{
                background: "var(--mateBlack)",
                color: "#FFFFFF",
                width: "370px",
                mt: "4rem",
                "&:hover": {
                  backgroundColor: "var(--mateBlack)",
                },
              }}
              type="submit"
            >
              Add This Account
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default AdminAddStaff;
