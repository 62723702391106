import React, { useEffect, useState } from "react";

import {
  Pagination,
  Stack,
  CircularProgress,
  Grid,
  Modal,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { format } from "date-fns";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import SearchIcon from "@mui/icons-material/Search";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AccountDetails = () => {
  const [open, setOpen] = React.useState(false);

  const user = secureLocalStorage.getItem("admin-info");
  const agentId = user?.user?.agentId;

  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contact, setContact] = useState("");
  const [role, setRole] = useState("");
  const [id, setId] = useState("");
  const [stateChanges, setStateChanges] = useState(false);

  const handleOpen = (data) => {
    setFname(data.fname);
    setLname(data.lname);
    setEmail(data.email);
    setPassword(data.password);
    setRole(data.role);
    setContact(data.phone);
    setId(data.id);

    setOpen(true);
  };
 
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState([]);
  const [mainAgentData, setMainAgentData] = useState([]);
  //  pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsloading] = useState(false);

  let size = 20;
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setMainAgentData(paymentData.slice((value - 1) * size, value * size));
  };

  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Auth/admin.php?all`)
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.data.number_of_page);
        setMainAgentData(data?.data);
        setPaymentData(data?.data);
        setIsloading(true);
        setStateChanges(true);
      });
  }, [agentId, page, open, stateChanges]);

  //  search functionality handle
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = paymentData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setMainAgentData(filterData);
    } else if (searchInput === "") {
      setMainAgentData(paymentData);
    }
  };

  const handleDelete = async (id) => {
    const url = `https://api.flyjatt.com/v1/Auth/admin.php?delete`;
    setStateChanges(false);
    let body = JSON.stringify({
      id: id,
    });
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        setStateChanges(false);
        if (data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "success",
            text: "Delete Successfully",
            confirmButtonText: "ok",
          }).then(function () {
            setStateChanges(false);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: `${data.message}`,
            confirmButtonText: "ok",
          }).then(function () {
            setStateChanges(false);
          });
        }
      });
  };

  const handleAdd = (e) => {
    e.preventDefault();

    let url = `https://api.flyjatt.com/v1/Auth/admin.php?edit`;
    let body = JSON.stringify({
      fname: fName,
      lname: lName,
      email: email,
      password: password,
      phone: contact,
      role: role,
      id: id,
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        setOpen(false);
        if (data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "success",
            text: "Update Successfully",
            confirmButtonText: "ok",
          }).then(function () {
            setOpen(false);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: `${data.message}`,
            confirmButtonText: "ok",
          });
        }
      });
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "24px",
            color: "#222222",
            fontFamily: "poppins",
          }}
        >
          User Accounts
        </Typography>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Box
            sx={{
              background: "var(--primary-color)",
              height: "35px",
              borderRadius: "20px",
              input: {
                border: "none",
                background: "none",
                outline: "none",
                float: "left",
                padding: 0,
                color: "white",
                fontSize: "16px",
                fontWeight: "normal",
                transition: "all 0.4s ease-in-out",
                lineHeight: "35px",
                width: "0px",
              },
              "input::placeholder": { color: "white" },
              "&:hover input": {
                width: "235px",
                padding: "0 10px",
              },
              "&:hover button": {
                background: "white",
                color: "black",
              },
              button: {
                border: "none",
                color: "white",
                float: "right",
                minHeight: "fit-content",
                width: "35px",
                height: "35px",
                borderRadius: "50%",
                background: "var(--primary-color)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transition: "all 0.4s ease-in-out",
                cursor: "pointer",
                textDecoration: "none",
              },
            }}
          >
            <input
              type="text"
              placeholder="Search....."
              onChange={handelSearchItems}
            />
            <button>
              <SearchIcon />
            </button>
          </Box>
          <Button
            style={{
              padding: "5px 10px",
              color: "var(--white)",
              background: "var(--secondary-color)",
              border: "none",
              cursor: "pointer",
              borderRadius: "4px",
              textTransform: "capitalize",
              fontSize: "14px",
            }}
            onClick={() => {
              navigate("/admin/addstaff");
            }}
          >
            Add Account
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "5px",
          "&::-webkit-scrollbar": {
            width: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "0px",
            background: "var(--secondary-color)",
          },
          height: "calc(100vh - 180px)",
          overflowY: "scroll",
          table: {
            width: "100%",
            position: "relative",
            borderCollapse: "collapse",
          },
          th: {
            position: "sticky",
            top: 0,
            zIndex: "999",
            background: "var(--primary-color)",
            color: "white",
            border: "1px solid #ddd",
            padding: "8px 12px",
            fontSize: "14px",
            fontWeight: "500",
          },
          td: {
            textAlign: "center",
            verticalAlign: "middle",
            border: "1px solid #ddd !important",
            padding: "5px !important",
            fontSize: "12px",
          },
          "tr:nth-child(even)": {
            background: "#f2f2f2",
          },
          "tr:hover": {
            background: "#ddd",
          },
        }}
      >
        {isLoading ? (
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Phone</th>
                <th>Operation</th>
              </tr>
            </thead>
            <tbody>
              {mainAgentData.map((data, index) => (
                <tr key={index}>
                  <td>{data?.fname || "First Name"}</td>
                  <td>{data?.lname}</td>
                  <td>{data?.email}</td>
                  <td>{data?.role}</td>
                  <td>{data?.phone}</td>
                  <td>
                    <EditIcon
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "22px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpen(data)}
                    />
                    <DeleteIcon
                      style={{
                        color: "tomato",
                        fontSize: "22px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(data?.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "38vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          my: 1,
          py: 1,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "8px",
          borderTop: "1px solid var(--primary-color)",
        }}
      >
        <Typography style={{ fontSize: "15px", color: "#222222" }}>
          Showing Results {page} - {pageCount}
        </Typography>
        <ThemeProvider theme={theme}>
          <Stack spacing={2}>
            <Pagination
              size="small"
              count={pageCount}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </ThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontWeight: "500px",
                  fontSize: "23px",
                  color: "#003566",
                  textAlign: "center",
                }}
                mb={2}
              >
                Update Admin
              </Typography>

              <form onSubmit={handleAdd}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        First Name
                      </Typography>
                      <input
                        required
                        type="text"
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                        placeholder="First Name"
                        value={fName}
                        onChange={(e) => setFname(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                        mb={1}
                      >
                        Last Name
                      </Typography>
                      <input
                        required
                        type="text"
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                        placeholder="Last Name"
                        value={lName}
                        onChange={(e) => setLname(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        Email
                      </Typography>
                      <input
                        required
                        type="text"
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                        value={email}
                        placeholder="Email Name"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        Password
                      </Typography>
                      <input
                        required
                        type="password"
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                        value={password}
                        placeholder="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        Contact Number
                      </Typography>
                      <input
                        required
                        type="text"
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                        value={contact}
                        placeholder="Contact Number"
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={4}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        Role
                      </Typography>

                      <select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                      >
                        <option>Select Role</option>
                        <option value={"Admin"}>Admin</option>
                        <option value={"Accountant"}>Accountant</option>
                        <option value={"Staff"}>Staff</option>
                      </select>
                    </Box>
                  </Grid>
                </Grid>

                <Button
                  sx={{
                    background: "var(--mateBlack)",
                    color: "#FFFFFF",
                    width: "370px",
                    mt: "4rem",
                    "&:hover": {
                      backgroundColor: "var(--mateBlack)",
                    },
                  }}
                  type="submit"
                >
                  Update This Account
                </Button>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AccountDetails;
