import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ServerDown from "../images/undraw/undraw_server_down_s-4-lk.svg";

import Swal from "sweetalert2";

const useAuthentication = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const adminLogin = (loginData, location, navigate) => {
    setIsLoading(false);
    secureLocalStorage.setItem("state", loginData);
    let url = `https://api.flyjatt.com/v1/Auth/admin.php?login`;
    let body = JSON.stringify({
      email: loginData.email.trim(),
      password: loginData.password.trim(),
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        if (data?.status === "success") {
          secureLocalStorage.setItem("admin-info", data);
          if (data?.data?.role === "Admin") {
            const destination = "/admin/dashboard";

            navigate(destination, {
              state: "Admin",
            });
          } else if (data?.data?.role === "Accountant") {
            const destination = "/admin/dashboard";
            navigate(destination, {
              state: "Accountant",
            });
          } else if (data?.data?.role === "Staff") {
            const destination = "/admin/dashboard";
            navigate(destination, {
              state: "Staff",
            });
          } else {
            const destination = "/admin/dashboard";
            navigate(destination, {
              state: "Staff",
            });
          }
        } else {
          secureLocalStorage.removeItem("admin-info");
          setError(data.message);
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Error!",
          html: `<strong>For any Query contact support@sixsense.tech or <strong>01755-572099, 09606912912.</strong>`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Please Try Again!",
        });
      });
    // .finally(() => setIsLoading(true));
  };

  const logout = () => {
    secureLocalStorage.removeItem("user-info");
    secureLocalStorage.removeItem("admin-info");
    secureLocalStorage.removeItem("commissionData");
    secureLocalStorage.removeItem("state");
    navigate("/");
  };

  return {
    adminLogin,
    // loginUser,
    logout,
    isLoading,
    error,
  };
};

export default useAuthentication;
