import {
    Box,
    Button,
    Card,
    Container,
    Grid,
    Link,
    Pagination,
    Switch,
    Tooltip,
    Typography,
  } from "@mui/material";
  import commaNumber from "comma-number";
  import Loader from "../../images/loader/Render.gif";
  import React, { useEffect, useState } from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import { Stack } from "@mui/system";
  import SearchIcon from "@mui/icons-material/Search";
  import { format } from "date-fns";
  import { createTheme, ThemeProvider } from "@mui/material/styles";
  import { AdminHeader } from "../AdminHeader/AdminHeader";
  import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
  import Swal from "sweetalert2";
  import secureLocalStorage from "react-secure-storage";
  
  import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
  
  const DetailsVisa = () => {
    const user = secureLocalStorage.getItem("admin-info");
    const actionby = user?.data?.fname + user?.data?.lname;
    const navigate = useNavigate();
  
    const [reFetch, setRefetch] = useState(true);
  
    const location = useLocation();
    const visa = location?.state;
  
    const [isLoading, setIsLoading] = React.useState(true);
    const [text, setText] = React.useState("");
    const [noteData, setNoteData] = React.useState([]);
    const [statusCheck, setStatusCheck] = React.useState([]);
  
    useEffect(() => {
      const url = `https://api.flyjatt.com/v1/Visa/note.php?visaref=${visa?.id}`;
      const url1 = `https://api.flyjatt.com/v1/Visa/index.php?id=${visa?.id}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setNoteData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setIsLoading(false);
          Swal.fire({
            imageUrl: ServerDown,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Failed! to Fetch",
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Try Another Time",
          }).then(() => {
            setIsLoading(false);
            navigate("/admin/dashboard");
          });
        });
      fetch(url1)
        .then((res) => res.json())
        .then((data) => {
          setStatusCheck(data[0]);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          setIsLoading(false);
          Swal.fire({
            imageUrl: ServerDown,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Failed! to Fetch",
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Try Another Time",
          }).then(() => {
            setIsLoading(false);
            navigate("/admin/dashboard");
          });
        });
      //todo: end of fetch all data
    }, [isLoading]);
  
    const handleChangestatus = async (data) => {
      setIsLoading(true);
  
      const url = `https://api.flyjatt.com/v1/Visa/index.php?status`;
  
      const body = {
        method: "POST",
        "application-type": "application/json",
        body: JSON.stringify({
          id: visa?.id,
          status: data?.status === "Open" ? "Close" : "Open",
        }),
      };
      await fetch(url, body)
        .then((res) => res.json())
        .then((data) => {
          if (data.status.toLowerCase() === "success") {
            Swal.fire({
              title: data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsLoading(true);
            });
          } else {
            Swal.fire({
              title: data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsLoading(true);
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: err.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          });
        });
    };
    const handleChangePayment = async (data) => {
      setIsLoading(true);
  
      const url = `https://api.flyjatt.com/v1/Visa/index.php?payment`;
  
      const body = {
        method: "POST",
        "application-type": "application/json",
        body: JSON.stringify({
          id: visa?.id,
          status: data?.payment === "Paid" ? "Unpaid" : "Paid",
        }),
      };
      await fetch(url, body)
        .then((res) => res.json())
        .then((data) => {
          if (data.status.toLowerCase() === "success") {
            Swal.fire({
              title: data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsLoading(true);
            });
          } else {
            Swal.fire({
              title: data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsLoading(true);
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: err.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          });
        });
    };
  
    const handleNote = async (e) => {
      setIsLoading(true);
      const url = "https://api.flyjatt.com/v1/Visa/note.php?add";
  
      e.preventDefault();
      e.target.reset();
      const body = {
        method: "POST",
        "application-type": "application/json",
        body: JSON.stringify({
          visaref: visa?.id,
          note: text,
          actionby: actionby,
        }),
      };
      await fetch(url, body)
        .then((res) => res.json())
        .then((data) => {
          if (data.status.toLowerCase() === "success") {
            Swal.fire({
              title: data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsLoading(true);
            });
          } else {
            Swal.fire({
              title: data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(function () {
              setIsLoading(true);
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            title: err.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          });
        });
    };
  
    return (
      <Box>
        <AdminHeader />
        <Box mt={3}>
          <Container>
            <Typography sx={{ color: "var(--mateBlack)", fontSize: "23px" }}>
              Visa Management
            </Typography>
            <Box my={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                    Full Name : {visa?.full_name}{" "}
                  </Typography>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                    Nationality : {visa?.nationality}{" "}
                  </Typography>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                    Phone : {visa?.phone}{" "}
                  </Typography>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                    Email : {visa?.email}{" "}
                  </Typography>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                    Departure Country : {visa?.depcountry}{" "}
                  </Typography>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                  Destination Country: {visa?.descountry}{" "}
                  </Typography>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                    Pupose : {visa?.pupose}{" "}
                  </Typography>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                    Stay Days : {visa?.stayday}{" "}
                  </Typography>
                  <Typography sx={{ color: "var(--btn-bg)", fontSize: 14 }}>
                    Requested at : {visa?.created_at}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Box textAlign="center">
                  <Typography>
                    Status:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Tooltip
                      title={statusCheck?.payment === "Open" ? "Close" : "Open"}
                    >
                      <Switch
                        checked={statusCheck?.status === "Open" ? true : false}
                        onChange={() => handleChangestatus(statusCheck)}
                      />
                    </Tooltip>
                  </Typography>
                  <Typography>
                    Payment:&nbsp;
                    <Tooltip
                      title={
                        statusCheck?.payment === "Paid" ? "Unpaid" : "Paid"
                      }
                    >
                      <Switch
                        checked={statusCheck?.payment === "Paid" ? true : false}
                        onChange={() => handleChangePayment(statusCheck)}
                      />
                    </Tooltip>
                  </Typography>
                </Box>
              </Grid>
              </Grid>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ bgcolor: "#222222", py: 1, px: 2, my: 3 }}
              >
                <Typography color="white">Notes</Typography>
                <Typography color="white">Updated By</Typography>
              </Stack>
  
            
              {noteData?.map((data) => (
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} sm={8}>
                    <Typography
                      sx={{
                        bgcolor: "var(--third-color)",
                        py: 2,
                        px: 2,
                        color: "#222222",
                      }}
                    >
                      {data?.notes}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      sx={{
                        bgcolor: "var(--third-color)",
                        py: 2,
                        px: 2,
                        color: "#222222",
                      }}
                    >
                      {data?.created_by}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
              </Grid>
                </Grid>

              ))}
  
              <Box my={2}>
                <form onSubmit={handleNote}>
                  <textarea
                    rows="4"
                    //   cols="100"
                    style={{ width: "100%", borderRadius: "5px" }}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Enter Note..."
                  ></textarea>
                  <Box textAlign="right">
                    <Button
                      type="submit"
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "white",
                        "&:hover": {
                          bgcolor: "var(--primary-color)",
                        },
                      }}
                    >
                      submit
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    );
  };
  
  export default DetailsVisa;
  