import { Navigate, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const PrivateRoute = ({ children, isLoading, role, isAccount, ...rest }) => {
  const location = useLocation();
  const user = secureLocalStorage.getItem("admin-info");

  if (user?.data?.email) {
    return children;
  }
  return <Navigate to="/" state={{ from: location }} />;
};

export default PrivateRoute;
