import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  Pagination,
  Stack,
} from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { AdminHeader } from "../../AdminHeader/AdminHeader";
import SearchIcon from "@mui/icons-material/Search";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import commaNumber from "comma-number";
import { format } from "date-fns";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReConfirm from "../../../images/undraw/undraw_confirmation_re_b6q5.svg";
import { Calendar } from "react-date-range";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentManagement = () => {
  const user = secureLocalStorage.getItem("admin-info");
  const adminName = `${user?.data?.fname}${" "}${user?.data?.lname}`;

  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [allPaymentData, setAllPaymentData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  //  pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 20;
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setPaymentData(allPaymentData?.slice((value - 1) * size, value * size));
  };

  const [loading, setLoading] = useState(false);

  //todo: for handle Filter
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "dd MMM yyyy"));

  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Deposit/index.php?all`)
      .then((res) => res.json())
      .then((data) => {
        const count = data?.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setPaymentData(data);
        setAllPaymentData(data);
        setIsloading(true);
      });
  }, [page]);

  const handleChangeOption = (e) => {
    const status = e.target.value?.replaceAll(/\s/g, "")?.toLowerCase();
    if (status !== "all") {
      const filterData = allPaymentData.filter((item) => {
        return item?.status?.replaceAll(/\s/g, "")?.toLowerCase() === status;
      });

      setPaymentData(filterData);
    } else {
      setPaymentData(allPaymentData);
    }
  };

  //  search functionality handle
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = allPaymentData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setPaymentData(filterData);
    } else if (searchInput === "") {
      setPaymentData(paymentData);
    }
  };

  // modal
  const [open, setOpen] = React.useState(false);

  const [id, setId] = useState("");
  const [reason, setReason] = useState("");

  const handleClickOpen = (depositId) => {
    setOpen(true);
    setId(depositId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // payment approved function
  const handleApprove = (depositId, actionBy) => {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You Wants to Approve This Deposit?",
      showCancelButton: true,
      confirmButtonColor: "var(--secondary-color)",
      confirmButtonText: "Approve👍",
      cancelButtonColor: "var(--primary-color)",
      cancelButtonText: "Cancel",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setLoading(true);
        let url = `https://api.flyjatt.com/v1/Deposit/index.php?approved`;
        let body = JSON.stringify({
          depositId: depositId,
          actionBy: adminName,
        });

        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "success") {
              Swal.fire({
                icon: "success",
                title: "success",
                text: "Payment Approved Successfully",
                confirmButtonText: "ok",
              }).then(() => {
                navigate(0);
              });
            }
          });
      }
      setLoading(true);
    });
  };

  // payment reject function
  const handleReject = () => {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You Wants to Reject This Deposit?",
      showCancelButton: true,
      confirmButtonColor: "var(--secondary-color)",
      confirmButtonText: "Reject❌",
      cancelButtonColor: "var(--primary-color)",
      cancelButtonText: "Cancel",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let url = `https://api.flyjatt.com/v1/Deposit/index.php?rejected`;
        let body = JSON.stringify({
          depositId: id,
          actionBy: adminName,
          reason: reason,
        });

        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "success") {
              Swal.fire({
                icon: "success",
                title: "success",
                text: "Payment Reject Successfully",
                confirmButtonText: "ok",
              }).then(() => {
                navigate(0);
              });
            }
          });
      }
    });

    handleClose();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const [paymentMethod, setPaymentMethod] = useState("");
  const handleMethod = (e) => {
    setPaymentMethod(e.target.value);
    if (e.target.value === "" && transactionType === "") {
      setPageCount(Math.ceil(allPaymentData.length / size));
      setPaymentData(allPaymentData?.slice(0, size));
    } else if (e.target.value === "cash" && transactionType === "") {
      const filter = allPaymentData?.filter(
        (item) => item?.paymentway?.toLowerCase() === "cash"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (e.target.value === "bank" && transactionType === "") {
      const filter = allPaymentData?.filter(
        (item) => item?.paymentway?.toLowerCase() === "bank"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (e.target.value === "cash" && transactionType === "pending") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (e.target.value === "cash" && transactionType === "approved") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (e.target.value === "cash" && transactionType === "rejected") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (e.target.value === "bank" && transactionType === "pending") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (e.target.value === "bank" && transactionType === "approved") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (e.target.value === "bank" && transactionType === "rejected") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    }
  };
  const [transactionType, setTransactionType] = useState("");
  const handleTransactionType = (e) => {
    setTransactionType(e.target.value);
    if (paymentMethod === "" && e.target.value === "") {
      setPageCount(Math.ceil(allPaymentData.length / size));
      setPaymentData(allPaymentData?.slice(0, size));
    } else if (paymentMethod === "" && e.target.value === "pending") {
      const filter = allPaymentData?.filter(
        (item) => item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (paymentMethod === "" && e.target.value === "approved") {
      const filter = allPaymentData?.filter(
        (item) => item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (paymentMethod === "" && e.target.value === "rejected") {
      const filter = allPaymentData?.filter(
        (item) => item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (paymentMethod === "cash" && e.target.value === "pending") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (paymentMethod === "cash" && e.target.value === "approved") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (paymentMethod === "cash" && e.target.value === "rejected") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "cash" &&
          item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (paymentMethod === "bank" && e.target.value === "pending") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "pending"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (paymentMethod === "bank" && e.target.value === "approved") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "approved"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    } else if (paymentMethod === "bank" && e.target.value === "rejected") {
      const filter = allPaymentData?.filter(
        (item) =>
          item?.paymentway?.toLowerCase() === "bank" &&
          item?.status?.toLowerCase() === "rejected"
      );
      setPageCount(Math.ceil(filter.length / size));
      setPaymentData(filter?.slice(0, size));
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const filterData = allPaymentData.filter((item) => {
      return (
        new Date(item?.created_at).getDate() >= new Date(startDate).getDate() &&
        new Date(item?.created_at).getDate() <= new Date(endDate).getDate()
      );
    });

    setPaymentData(filterData);
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Typography
          variant="span"
          sx={{
            fontWeight: 400,
            fontSize: "24px",
            color: "#222222",
            py: 1,
          }}
        >
          Agent Deposits
        </Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                background: "var(--primary-color)",
                height: "35px",
                borderRadius: "20px",
                input: {
                  border: "none",
                  background: "none",
                  outline: "none",
                  float: "left",
                  padding: 0,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "normal",
                  transition: "all 0.4s ease-in-out",
                  lineHeight: "35px",
                  width: "0px",
                },
                "input::placeholder": { color: "white" },
                "&:hover input": {
                  width: "235px",
                  padding: "0 10px",
                },
                "&:hover button": {
                  background: "white",
                  color: "black",
                },
                button: {
                  border: "none",
                  color: "white",
                  float: "right",
                  minHeight: "fit-content",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  background: "var(--primary-color)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "all 0.4s ease-in-out",
                  cursor: "pointer",
                  textDecoration: "none",
                },
              }}
            >
              <input
                type="text"
                placeholder="Search....."
                onChange={handelSearchItems}
              />
              <button>
                <SearchIcon />
              </button>
            </Box>
            <Box>
              <form onSubmit={handleFilter}>
                <Grid
                  container
                  spacing={1}
                  alignItems="end"
                  justifyContent="end"
                  sx={{
                    zIndex: "9999",
                    input: {
                      width: "100%",
                      padding: "5px 10px",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "5px",
                      cursor: "pointer",
                      outline: "none",
                    },
                  }}
                >
                  <Grid
                    style={{ position: "relative" }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Box>
                      <input
                        title="Enter Start Date"
                        required
                        id="startDate"
                        name="startDate"
                        type="text"
                        value={startDate}
                        readOnly
                        onClick={() => {
                          setOpenEndDate(false);
                          setOpenStartDate((prev) => !prev);
                        }}
                      />
                    </Box>
                    {openStartDate && (
                      <Calendar
                        color={"var(--primary-color)"}
                        date={new Date(startDate)}
                        onChange={(date) => {
                          setStartDate(format(new Date(date), "dd MMM yyyy"));
                          setOpenStartDate(false);
                        }}
                        maxDate={new Date()}
                        months={1}
                        className="new-dashboard-calendar"
                        name="dashboard-calendar"
                      />
                    )}
                  </Grid>

                  <Grid
                    style={{ position: "relative" }}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Box>
                      <input
                        title="Enter End Date"
                        required
                        id="endDate"
                        name="endDate"
                        type="text"
                        value={endDate}
                        readOnly
                        onClick={() => {
                          setOpenEndDate((prev) => !prev);
                          setOpenStartDate(false);
                        }}
                      />
                    </Box>
                    {openEndDate && (
                      <Calendar
                        color="var(--primary-color)"
                        date={new Date(endDate)}
                        onChange={(date) => {
                          setEndDate(format(new Date(date), "dd MMM yyyy"));
                          setOpenEndDate(false);
                        }}
                        months={1}
                        className="new-dashboard-calendar"
                      />
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      gap: "5px",
                    }}
                  >
                    <Button
                      title="Click to Filter"
                      sx={{
                        background: "var(--secondary-color)",
                        color: "white",
                        padding: "5px 20px !important",
                        fontSize: "12px !important",
                        width: "100%",
                        "&:hover": {
                          background: "var(--secondary-color)",
                          color: "white",
                        },
                      }}
                      type="submit"
                    >
                      FILTER
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      gap: "5px",
                    }}
                  >
                    <Button
                      title="Click to Reset"
                      sx={{
                        background: "var(--secondary-color)",
                        color: "white",
                        padding: "5px 20px !important",
                        fontSize: "12px !important",
                        width: "100%",
                        "&:hover": {
                          background: "var(--secondary-color)",
                          color: "white",
                        },
                      }}
                      onClick={() => setPaymentData(allPaymentData)}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>

            <select
              onChange={handleMethod}
              value={paymentMethod}
              style={{
                width: "150px",
                borderRadius: "4px",
                outline: "none",
                height: "100%",
                padding: "5px 10px",
                color: "var(--primary-color)",
                fontWeight: "normal",
                borderColor: "var(--secondary-color)",
                fontSize: "14px",
              }}
            >
              <option value="">Show All</option>
              <option value="cash">Cash</option>
              <option value="bank">Bank Transfer</option>
            </select>
            <select
              value={transactionType}
              onChange={handleTransactionType}
              style={{
                width: "150px",
                borderRadius: "4px",
                outline: "none",
                height: "100%",
                padding: "5px 10px",
                color: "var(--primary-color)",
                fontWeight: "normal",
                borderColor: "var(--secondary-color)",
                fontSize: "14px",
              }}
            >
              <option value="">Show All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </Box>
        </Box>
        <Box
          sx={{
            mt: "5px",
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "0px",
              background: "var(--secondary-color)",
            },
            height: "calc(100vh - 180px)",
            overflowY: "scroll",
            table: {
              width: "100%",
              position: "relative",
              borderCollapse: "collapse",
            },
            th: {
              position: "sticky",
              top: 0,
              zIndex: "999",
              background: "var(--primary-color)",
              color: "white",
              border: "1px solid #ddd",
              padding: "8px 12px",
              fontSize: "14px",
              fontWeight: "500",
            },
            td: {
              textAlign: "center",
              verticalAlign: "middle",
              border: "1px solid #ddd !important",
              padding: "5px !important",
              fontSize: "12px",
            },
            "tr:nth-child(even)": {
              background: "#f2f2f2",
            },
            "tr:hover": {
              background: "#ddd",
            },
          }}
        >
          {isLoading === true ? (
            <table>
              <thead>
                <tr>
                  <th>AgentId</th>
                  {/* <th>Cr./Dr.</th> */}
                  <th>Status</th>
                  {/* <th>Company</th> */}
                  <th>Type</th>
                  <th>Sender Acc</th>
                  {paymentMethod === "bank" && <th>Receiver</th>}

                  <th>Reference</th>
                  <th>Amount </th>
                  <th>Date </th>
                  <th>Attachment </th>
                  <th>Action</th>
                  <th>ActionBy</th>
                  <th>Reason</th>
                  <th>Company</th>
                </tr>
              </thead>
              <tbody>
                {paymentData
                  ? paymentData?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.agentId || "AgentId"}</td>
                        {/* <td>{"Credit/Debit"}</td> */}
                        <td>
                          {data?.status ? (
                            <span
                              className={`${data?.status
                                ?.toLowerCase()
                                ?.split(" ")
                                ?.join("-")}-btn`}
                            >
                              {data?.status}
                            </span>
                          ) : (
                            "Status"
                          )}
                        </td>
                        {/* <td>{data?.company || "Company"}</td> */}
                        <td>{data?.paymentway || "PaymentWay"}</td>
                        <td>{data?.sender || "Sender Acc"}</td>
                        {paymentMethod === "bank" && (
                          <td>{data?.receiver || "Bank Name"}</td>
                        )}

                        <td>{data?.ref || "No Data"}</td>
                        <td>
                          {commaNumber(Number.parseInt(data?.amount || 0))} SAR
                        </td>
                        <td>
                          {data?.created_at
                            ? format(
                                new Date(data?.created_at),
                                "dd MMM yyyy hh:mm a"
                              )
                            : "Traction Date"}
                        </td>
                        <td>
                          <Link
                            id="anchor"
                            href={`https://api.flyjatt.com/assets/deposit/${data?.agentId}/${data?.attachment}`}
                            target="_blank"
                            rel="noreferrer"
                            underline="hover"
                            sx={{
                              color: "var(--secondary-color)!important",
                              fontWeight: "600",
                            }}
                          >
                            View
                          </Link>
                        </td>
                        <td>
                          {data?.status === "pending" ? (
                            <Box sx={{ display: "flex" }}>
                              <IconButton
                                sx={{ color: "#0E8749" }}
                                onClick={() =>
                                  handleApprove(data?.depositId, data?.actionBy)
                                }
                              >
                                {/* {!loading ? ( */}
                                <CheckCircleIcon sx={{ fontSize: "20px" }} />
                                {/* // ) : (
                                //   "Wait for response"
                                // )} */}
                              </IconButton>
                              <IconButton
                                sx={{ color: "var(--remove-color)" }}
                                onClick={() => handleClickOpen(data?.depositId)}
                              >
                                <CancelIcon sx={{ fontSize: "20px" }} />
                              </IconButton>
                            </Box>
                          ) : (
                            <span
                              style={{
                                border: "none",
                                borderRadius: "5px",
                                width: "100%",
                              }}
                              className={`${data?.status
                                ?.toLowerCase()
                                ?.split(" ")
                                ?.join("-")}-btn`}
                            >
                              {data?.status}
                            </span>
                          )}
                        </td>
                        <td>{data?.actionBy || "N/A"}</td>
                        <td>{data?.remarks || "N/A"}</td>
                        <td>{data?.company || "No Data"}</td>
                        {/* <td>
                          <a href={`tel:+${data?.phone}`}>
                            <PhoneIcon
                              style={{ color: "#003566", fontSize: "18px" }}
                            />
                          </a>
                          <a
                            href={`https://wa.me/+${data?.phone}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <WhatsAppIcon
                              style={{ color: "green", fontSize: "18px" }}
                            />
                          </a>
                        </td> */}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "38vh",
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {/* Rejection model star here */}
          <Box>
            <form>
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Rejection Reason"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <textarea
                      required
                      type="text"
                      rows="4"
                      placeholder="Enter Reason..."
                      style={{
                        width: "300px",
                        outline: "none",
                        padding: "6px 5px",
                        border: "1px solid var(--flyhub)",
                        borderRadius: "3px",
                      }}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </DialogContentText>
                  <Box pt={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <Button
                        sx={{
                          color: "#fff",
                          px: 3,
                          py: 0.3,
                          bgcolor: "red",
                          "&:hover": {
                            backgroundColor: "green",
                          },
                        }}
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        sx={{
                          color: "#fff",
                          px: 3,
                          py: 0.3,
                          bgcolor: "green",
                          "&:hover": {
                            backgroundColor: "green",
                          },
                        }}
                        onClick={handleReject}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Box>
                </DialogContent>
              </Dialog>
            </form>
          </Box>
          {/* Rejection model end here */}
          {/* <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  width: "100%",
                  my: 3,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  borderTop: "1px solid var(--primary-color)",
                  marginTop: "30px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    my: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",

                    marginTop: "8px",
                  }}
                >
                  <Typography style={{ fontSize: "15px", color: "#222222" }}>
                    Showing Results {page} - {pageCount}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Stack spacing={2}>
                      <Pagination
                        count={pageCount}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </Stack>
                  </ThemeProvider>
                </Box>
              </Box>
            </Grid>
          </Grid> */}
        </Box>
        <Box
          sx={{
            width: "100%",
            my: 1,
            py: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "8px",
            borderTop: "1px solid var(--primary-color)",
          }}
        >
          <Typography style={{ fontSize: "15px", color: "#222222" }}>
            Showing Results {page} - {pageCount}
          </Typography>
          <ThemeProvider theme={theme}>
            <Stack spacing={2}>
              <Pagination
                size="small"
                count={pageCount}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </ThemeProvider>
        </Box>
      </Container>
    </Box>
  );
};

export default PaymentManagement;
