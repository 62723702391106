import { Badge, Box, ClickAwayListener } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import secureLocalStorage from "react-secure-storage";
import Logo from "../../images/logo/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const AdminHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("admin-info");
  const agentId = user?.user?.agentId;

  const handleClickAway = () => {};

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Container>
          <Box
            sx={{
              height: "fit-content",
              width: "100%",
              display: "flex",
              alignItems: "center",
              py: 1,
            }}
          >
            {/* //todo:Logo  */}
            <Box
              sx={{
                width: { md: "20%", sm: "50%", xs: "50%" },
                bgcolor: "var(--white)",
                height: "100%",
                display: "flex",
                justifyContent: {
                  lg: "start",
                  md: "start",
                  sm: "center",
                  xs: "center",
                },
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/admin/dashboard")}
            >
              <Box>
                <img
                  src={Logo}
                  alt="logo"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "80%",
                height: "60px",
                display: { md: "flex", sm: "none", xs: "none" },
                alignItems: "center",
                justifyContent: "end",
                gap: "10px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  background: "var(--primary-color)",
                  height: "35px",
                  borderRadius: "20px",
                  input: {
                    border: "none",
                    background: "none",
                    outline: "none",
                    float: "left",
                    padding: 0,
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "normal",
                    transition: "all 0.4s ease-in-out",
                    lineHeight: "35px",
                    width: "0px",
                  },
                  "input::placeholder": { color: "white" },
                  "&:hover input": {
                    width: "235px",
                    padding: "0 10px",
                  },
                  "&:hover button": {
                    background: "white",
                    color: "black",
                  },
                  button: {
                    border: "none",
                    color: "white",
                    float: "right",
                    minHeight: "fit-content",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    background: "var(--primary-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.4s ease-in-out",
                    cursor: "pointer",
                    textDecoration: "none",
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Search....."
                  // onChange={handelSearchItems}
                />
                <button>
                  <SearchIcon />
                </button>
              </Box>
              <Badge
                badgeContent={4}
                color="primary"
                sx={{
                  ".MuiBadge-badge": {
                    background: "var(--primary-color)",
                  },
                }}
              >
                <NotificationsIcon
                  color="var(--secondary-color)"
                  sx={{ fontSize: "30px" }}
                />
              </Badge>
            </Box>
          </Box>
        </Container>
      </Box>
    </ClickAwayListener>
  );
};
