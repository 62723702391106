import {
  Box,
  Typography,
  Stack,
  Container,
  Grid,
  Modal,
  Tooltip,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AdminHeader } from "../../AdminHeader/AdminHeader";
import Markup from "../../Markup/FlightMarkUp/Markup";
import AgentMarkupDetails from "./AgentMarkupDetails";
import ServerDown from "../../../images/undraw/undraw_server_down_s-4-lk.svg";
import AgentMarkup from "./AgentMarkup";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Agentdata = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state;
  const user = secureLocalStorage.getItem("user-info");
  const adminUser = user?.data?.name;
  const agentId = location?.state?.agentId;
  const [otherData, setotherData] = useState({});
  const [image, setImage] = useState();

  const handleOthersData = (e) => {
    setotherData({ ...otherData, [e.target.name]: e.target.value });
  };
  const [open, setOpen] = useState(false);
  const [logoOpen, setLogoOpen] = useState(false);
  const [agentUpdate, setAgentUpdate] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  //   const { name, email, phone, address } = data;
  const [allData, setAllData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Agent/index.php?agentId=${agentId}`)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setAllData(data);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Failed! to Fetch",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Another Time",
        }).then(() => {
          setIsLoading(false);
          navigate(0);
        });
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target.value;

    //     setAgentUpdate({ ...agentUpdate, [name]: value });
    //   };

    setAgentUpdate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddLogo = async (e) => {
    const formData = new FormData();
    formData.append("file", image);

    e.preventDefault();
    const url = `https://api.flyjatt.com/v1/Agent/companylogo.php?agentId=${agentId}`;

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios.post(url, formData, config).then((res) => {
      setLogoOpen(false);
      if (res?.data?.status === "success") {
        Swal.fire({
          icon: "success",
          title: res?.data?.status,
          html: res?.data?.message,
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          navigate(0);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: res?.data?.status,
          html: res?.data?.message,
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          navigate(0);
        });
      }
    });
  };

  const handleOtherSubmit = async (e) => {
    const formData = new FormData();
    formData.append("agentId", agentId);
    formData.append("reference", otherData.reference);
    formData.append("details", otherData.details);
    formData.append("amount", otherData.amount);
    formData.append("createdBy", adminUser);
    formData.append("file", image);

    e.preventDefault();
    const url = "https://api.flyjatt.com/v1/Agent/addother.php";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios.post(url, formData, config).then((res) => {
      setOpen(false);
      if (res?.data?.status === "success") {
        Swal.fire({
          icon: "success",
          title: res?.data?.status,
          html: res?.data?.message,
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          navigate(0);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: res?.data?.status,
          html: res?.data?.message,
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          navigate(0);
        });
      }
    });
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontSize: "17px",
                    fontWeight: 500,
                  }}
                >
                  <Box mb={0.5}>Company</Box>
                  <Box mb={0.5}>Balance</Box>
                  <Box mb={0.5}>Agent Id</Box>
                  <Box mb={0.5}>Agent&nbsp;Name</Box>
                  <Box mb={0.5}>Email</Box>
                  <Box mb={0.5}>Phone</Box>
                  <Box mb={0.5}>Address</Box>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                {isLoading ? (
                  "Loading..."
                ) : (
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontSize: "17px",
                      fontWeight: 400,
                    }}
                  >
                    <Box mb={0.5}>
                      <Tooltip title={allData?.company}>
                        <Box>{allData?.company || "N/A"}</Box>
                      </Tooltip>
                    </Box>
                    <Box mb={0.5}>
                      {allData?.balance || "N/A"}
                      {" SAR "}
                      <Button
                        size="small"
                        sx={{
                          ml: 3,
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          "&:hover": {
                            bgcolor: "var(--primary-color)",
                          },
                        }}
                        onClick={() => setOpen(true)}
                      >
                        Balance (+) or (-)
                      </Button>
                    </Box>
                    <Box mb={0.5}> {allData?.agentId || "N/A"}</Box>
                    <Box mb={0.5}> {allData?.name || "N/A"}</Box>
                    <Box mb={0.5}> {allData?.phone || "N/A"}</Box>
                    <Box mb={0.5}> {allData?.email || "N/A"}</Box>
                    <Box mb={0.5}> {allData?.address || "N/A"}</Box>
                  </Typography>
                )}
              </Grid>
            </Grid>
            {/* <Typography
              sx={{
                mt: 4,
                px: 5,
                py: 0.5,
                bgcolor: "var(--primary-color)",
                width: "fit-content",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              Agent Update
            </Typography> */}
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <Typography
              sx={{
                textAlign: "center",
                py: 0.8,
                color: "#fff",
                fontWeight: "500",
                cursor: "pointer",
                mb: 2,
                bgcolor: "var(--primary-color)",
              }}
              onClick={() =>
                navigate("/admin/agentbooking", {
                  state: agentId,
                })
              }
            >
              View Booking
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                py: 0.8,
                color: "#fff",
                fontWeight: "500",
                cursor: "pointer",
                mb: 2,
                bgcolor: "var(--primary-color)",
              }}
              onClick={() =>
                navigate("/admin/agentdeposit", {
                  state: agentId,
                })
              }
            >
              View Deposit{" "}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                py: 0.8,
                color: "#fff",
                fontWeight: "500",
                cursor: "pointer",
                mb: 2,
                bgcolor: "var(--primary-color)",
              }}
              onClick={() =>
                navigate("/admin/agentladger", {
                  state: agentId,
                })
              }
            >
              View Ledger
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                py: 0.8,
                color: "#fff",
                fontWeight: "500",
                cursor: "pointer",
                mb: 2,
                bgcolor: "var(--primary-color)",
              }}
              onClick={() =>
                navigate("/admin/agentsearch", {
                  state: agentId,
                })
              }
            >
              View Search
            </Typography>
            {allData?.logo === null ? null : (
              <Box
                width="200px"
                border="2px solid #222222"
                my={2}
                margin="auto"
              >
                <img
                  src={`https://api.flyjatt.com/${allData?.logo}`}
                  alt="AgentLogo"
                  width="100%"
                />
              </Box>
            )}
            <Typography
              sx={{
                mt: 1,
                textAlign: "center",
                py: 0.8,
                color: "#fff",
                fontWeight: "500",
                cursor: "pointer",
                mb: 2,
                bgcolor: "var(--primary-color)",
              }}
              onClick={() => setLogoOpen(true)}
            >
              Add Logo
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <AgentMarkup data={allData} />
          {/* <Box mt={2}>
            <AgentMarkupDetails data={allData} />
          </Box> */}
        </Box>
        {/* <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{ ...style, width: 300 }}
            style={{ border: "none", borderRadius: "5px" }}
          >
            <form onSubmit={handleSubmit}>
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontSize: "17px",
                    fontWeight: 500,
                  }}
                >
                  Agent ID:
                  <br />
                  Agent Name:
                  <br />
                  Email:
                  <br />
                  Phone:
                  <br />
                  Address:
                </Typography>
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontSize: "17px",
                    fontWeight: 500,
                  }}
                >
                  <input
                    type="text"
                    name="name"
                    value={data.name || ""}
                    onChange={handleInputChange}
                  />
                </Typography>
              </Stack>
            </form>
          </Box>
        </Modal> */}

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{ ...style, xs: "100%", sm: "80%", md: "60%" }}
            style={{ border: "none", borderRadius: "5px" }}
          >
            <Typography
              my={2}
              style={{
                color: "var(--mateBlack)",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Balance (+) or (-)
            </Typography>
            <Box>
              <form onSubmit={handleOtherSubmit}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    "& input": {
                      width: "100%",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    },
                  }}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <input
                      name="reference"
                      value={otherData?.reference || ""}
                      type="text"
                      placeholder="Reference..."
                      required
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      }}
                      onChange={handleOthersData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <input
                      name="details"
                      value={otherData?.details || ""}
                      type="text"
                      placeholder="Details..."
                      required
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      }}
                      onChange={handleOthersData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <input
                      name="amount"
                      value={otherData?.amount || ""}
                      type="text"
                      placeholder="Amount value Ex: (-10) or (10)"
                      required
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      }}
                      onChange={handleOthersData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <input
                      name="file"
                      //   value={otherData?.file || ""}
                      type="file"
                      required
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      }}
                      onChange={(e) => setImage(e.target.files[0])}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <button
                      type="submit"
                      style={{
                        width: "100%",
                        background: "var(--secondary-color)",
                        color: "white",
                        outline: "none",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        border: "1px solid var(--secondary-color)",
                      }}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={logoOpen}
          onClose={() => setLogoOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{ ...style, xs: "100%", sm: "80%", md: "60%" }}
            style={{ border: "none", borderRadius: "5px" }}
          >
            <Typography
              my={2}
              style={{
                color: "var(--mateBlack)",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              Add logo <br /> Only PNG image upload max size (2MB)
            </Typography>
            <Box>
              <form onSubmit={handleAddLogo}>
                <Box>
                  <input
                    name="file"
                    type="file"
                    required
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </Box>
                <Box mt={2}>
                  <button
                    type="submit"
                    style={{
                      width: "100%",
                      background: "var(--secondary-color)",
                      color: "white",
                      outline: "none",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      border: "1px solid var(--secondary-color)",
                      cursor: "pointer",
                    }}
                  >
                    Submit
                  </button>
                </Box>
              </form>
            </Box>
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

export default Agentdata;
