import {
  Box,
  Container,
  createTheme,
  Grid,
  Pagination,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AdminHeader } from "../../AdminHeader/AdminHeader";
import Success from "../../../images/undraw/undraw_completed_tasks_vs6q.svg";
import ServerDown from "../../../images/undraw/undraw_server_down_s-4-lk.svg";
import ReConfirm from "../../../images/undraw/undraw_confirmation_re_b6q5.svg";
import Swal from "sweetalert2";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

{
  /* <Link
            style={{
              textDecoration: "none",
              background: "var(--secondary-color)",
              color: "var(--white)",
              fontSize: "12px",
              padding: "5px 10px",
              borderRadius: "3px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            to={"/admin/markup"}
          >
            Add Markup
          </Link> */
}

const AgentMarkupDetails = ({ markupValue, agentId }) => {
  //   const agentId = data?.agentId;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [markUpData, setMarkUpData] = useState();
  const [allMarkUpData, setAllMarkUpData] = useState([]);
  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 15;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    // setAllAgentData(allAgentData.slice((value - 1) * size, value * size));
  };

  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Agent/index.php?agentId=${agentId}`)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setAllMarkUpData(data);
        setMarkUpData(data);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Failed! to Fetch",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Another Time",
        }).then(() => {
          setIsLoading(true);
          navigate("/admin/dashboard");
        });
      });
  }, [agentId]);

  if (isLoading) {
    return "Loading...";
  }
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          style={{
            color: "var(--mateBlack)",
            fontSize: "22px",
            fontWeight: "500",
          }}
        >
          Markup
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            mt: "5px",
            "&::-webkit-scrollbar": {
              width: "5px",
              height: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "0px",
              background: "var(--secondary-color)",
            },
            table: {
              width: "50%",
              position: "relative",
              borderCollapse: "collapse",
            },
            th: {
              position: "sticky",
              top: 0,
              zIndex: "999",
              background: "var(--primary-color)",
              color: "white",
              border: "1px solid #ddd",
              padding: "8px 12px",
              fontSize: "14px",
              fontWeight: "500",
            },
            td: {
              textAlign: "center",
              verticalAlign: "middle",
              border: "1px solid #ddd !important",
              padding: "5px !important",
              fontSize: "12px",
            },
            "tr:nth-child(even)": {
              background: "#f2f2f2",
            },
            "tr:hover": {
              background: "#ddd",
            },
          }}
        >
          <table>
            {markupValue === "void" ? (
              <tr>
                <th>VoidMarkUpType</th>
                <th>VoidMarkUpAmount</th>
              </tr>
            ) : markupValue === "refund" ? (
              <tr>
                <th>RefundMarkUpType</th>
                <th>RefundMarkUpAmount</th>
              </tr>
            ) : markupValue === "reissue" ? (
              <tr>
                <th>ReIssueMarkUpType</th>
                <th>ReIssueMarkUpAmount</th>
              </tr>
            ) : (
              <tr>
                <th>FlightMarkUpType</th>
                <th>FlightMarkUpAmount</th>
              </tr>
            )}

            <tbody>
              {markupValue === "void" ? (
                <tr>
                  <td>{markUpData?.voidmarkuptype || "N/A"}</td>
                  <td>{markUpData?.voidmarkup || "N/A"}</td>
                </tr>
              ) : markupValue === "refund" ? (
                <tr>
                  <td>{markUpData?.refundmarkuptype || "N/A"}</td>
                  <td>{markUpData?.refundmarkup || "N/A"}</td>
                </tr>
              ) : markupValue === "reissue" ? (
                <tr>
                  <td>{markUpData?.reissuemarkuptype || "N/A"}</td>
                  <td>{markUpData?.reissuemarkup || "N/A"}</td>
                </tr>
              ) : (
                <tr>
                  <td>{markUpData?.flightmarkuptype || "N/A"}</td>
                  <td>{markUpData?.flightmarkup || "N/A"}</td>
                </tr>
              )}
              <tr></tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default AgentMarkupDetails;
