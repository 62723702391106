import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import commaNumber from "comma-number";
import Loader from "../../images/loader/Render.gif";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { format } from "date-fns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdminHeader } from "../AdminHeader/AdminHeader";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import Swal from "sweetalert2";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const AllLicense = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [license, setLicense] = useState([]);
  const [allLicense, setAllLicense] = useState([]);
  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const size = 30;

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const url = `https://api.flyjatt.com/v1/License/index.php?all`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPageCount(Math.ceil(data?.length / size));
        setLicense(data);
        setAllLicense(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Failed! to Fetch",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Another Time",
        }).then(() => {
          setIsLoading(false);
          navigate("/admin/dashboard");
        });
      });
    //todo: end of fetch all data
  }, []);

  const handleChangeOption = (e) => {
    const status = e.target.value?.replaceAll(/\s/g, "")?.toLowerCase();
    if (status !== "all") {
      const filterData = allLicense.filter((item) => {
        return item?.Status?.replaceAll(/\s/g, "")?.toLowerCase() === status;
      });

      setLicense(filterData);
    } else {
      setLicense(allLicense);
    }
  };

  const handelSearchItems = (e) => {
    const searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = allLicense.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setLicense(filterData);
    } else if (searchInput === "") {
      setLicense(allLicense);
    }
  };

  return (
    <Box>
      <AdminHeader />
      <Box>
        <Container>
          {!isLoading ? (
            <Box>
              <Box>
                <Grid
                  container
                  // justifyContent={"space-between"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography
                      sx={{ color: "var(--mateBlack)", fontSize: "23px" }}
                    >
                      Driving License Management
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          background: "var(--primary-color)",
                          height: "35px",
                          borderRadius: "20px",
                          input: {
                            border: "none",
                            background: "none",
                            outline: "none",
                            float: "left",
                            padding: 0,
                            color: "white",
                            fontSize: "16px",
                            fontWeight: "normal",
                            transition: "all 0.4s ease-in-out",
                            lineHeight: "35px",
                            width: "0px",
                          },
                          "input::placeholder": { color: "white" },
                          "&:hover input": {
                            width: "235px",
                            padding: "0 10px",
                          },
                          "&:hover button": {
                            background: "white",
                            color: "black",
                          },
                          button: {
                            border: "none",
                            color: "white",
                            float: "right",
                            minHeight: "0px",
                            width: "35px",
                            height: "35px",
                            borderRadius: "50%",
                            background: "var(--primary-color)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            transition: "all 0.4s ease-in-out",
                            cursor: "pointer",
                            textDecoration: "none",
                          },
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Search....."
                          onChange={handelSearchItems}
                        />
                        <button>
                          <SearchIcon />
                        </button>
                      </Box>

                      {/* <select
                        onChange={handleChangeOption}
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                          padding: "5px 10px",
                          borderRadius: "4px",
                          outline: "none",
                        }}
                      >
                        <option value="all">&nbsp;Show All</option>
                        <option value="hold">&nbsp;Hold</option>
                        <option value="Issue In Processing">
                          &nbsp;Issue In Processing
                        </option>
                        <option value="Ticketed">&nbsp;Ticketed</option>

                        <option value="Void In Processing">
                          &nbsp;Void In Processing
                        </option>
                        <option value="Voided">&nbsp;Voided</option>
                        <option value="Void Rejected">
                          &nbsp;Void Rejected
                        </option>

                        <option value="Reissue In Processing">
                          &nbsp;Reissue In Processing
                        </option>
                        <option value="Reissued">&nbsp;Reissued</option>
                        <option value="Reissue Rejected">
                          &nbsp;Reissue Rejected
                        </option>

                        <option value="Refund In Processing">
                          &nbsp;Refund In Processing
                        </option>
                        <option value="Refunded">&nbsp;Refunded</option>
                        <option value="Refund Rejected">
                          &nbsp;Refund Rejected
                        </option>
                        <option value="Issue Rejected">
                          &nbsp;Issue Rejected
                        </option>
                        <option value="cancelled">&nbsp;Cancelled</option>
                      </select> */}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Box
                  sx={{
                    mt: "5px",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "0px",
                      background: "var(--secondary-color)",
                    },
                    height: "calc(100vh - 180px)",
                    overflowY: "scroll",
                    table: {
                      width: "100%",
                      position: "relative",
                      borderCollapse: "collapse",
                    },
                    th: {
                      position: "sticky",
                      top: 0,
                      zIndex: "999",
                      background: "var(--primary-color)",
                      color: "white",
                      border: "1px solid #ddd",
                      padding: "8px 12px",
                      fontSize: "14px",
                      fontWeight: "500",
                    },
                    td: {
                      textAlign: "center",
                      verticalAlign: "middle",
                      border: "1px solid #ddd !important",
                      padding: "5px !important",
                      fontSize: "12px",
                    },
                    "tr:nth-child(even)": {
                      background: "#f2f2f2",
                    },
                    "tr:hover": {
                      background: "#ddd",
                    },
                  }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>AgentId</th>
                        <th>Request&nbsp;Date</th>
                        <th>FullName</th>
                        <th>Nationality</th>
                        <th>Phone</th>
                        <th>Email</th>
                        {/* <th>Passport</th> */}
                        <th>Status</th>
                        <th>Payment</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {license?.map((item, index) => (
                        <tr key={{ index }}>
                          <td>{item?.agentId || "AgentId"}</td>
                          <td>{item?.created_at || "Date"}</td>
                          <td>{item?.full_name || "FullName"}</td>
                          <td>{item?.nationality || "Nationality"}</td>
                          <td>{item?.phone || "Phone"}</td>
                          <td>{item?.email || "Email"}</td>
                          <td
                            style={{
                              color: `${
                                item?.status?.toLowerCase() === "open"
                                  ? "green"
                                  : "red"
                              }`,
                            }}
                          >
                            {item.status || "status"}
                          </td>
                          <td
                            style={{
                              color: `${
                                item?.payment?.toLowerCase() === "paid"
                                  ? "green"
                                  : "red"
                              }`,
                            }}
                          >
                            {item.payment || "payment"}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate("/admin/details-license", {
                                state: item,
                              })
                            }
                          >
                            <NoteAltOutlinedIcon />
                          </td>
                          {/* <td>
                            <Link
                              id="anchor"
                              href={`https://api.flyjatt.com/assets/license/${item?.agentId}/${item?.passport_copy}`}
                              target="_blank"
                              rel="noreferrer"
                              underline="hover"
                              sx={{
                                color: "var(--secondary-color)!important",
                                fontWeight: "600",
                              }}
                            >
                              View
                            </Link>
                          </td> */}
                          {/* <td>
                            <Link
                              id="anchor"
                              href={`https://api.flyjatt.com/assets/license/${item?.agentId}/${item?.iqama_copy}`}
                              target="_blank"
                              rel="noreferrer"
                              underline="hover"
                              sx={{
                                color: "var(--secondary-color)!important",
                                fontWeight: "600",
                              }}
                            >
                              View
                            </Link>
                          </td> */}
                          {/* <td>
                            <Link
                              id="anchor"
                              href={`https://api.flyjatt.com/assets/license/${item?.agentId}/${item?.license_copy}`}
                              target="_blank"
                              rel="noreferrer"
                              underline="hover"
                              sx={{
                                color: "var(--secondary-color)!important",
                                fontWeight: "600",
                              }}
                            >
                              View
                            </Link>
                          </td> */}
                          {/* <td>
                            <Link
                              id="anchor"
                              href={`https://api.flyjatt.com/assets/license/${item?.agentId}/${item?.passport_size_photo}`}
                              target="_blank"
                              rel="noreferrer"
                              underline="hover"
                              sx={{
                                color: "var(--secondary-color)!important",
                                fontWeight: "600",
                              }}
                            >
                              View
                            </Link>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    my: 1,
                    py: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "8px",
                    borderTop: "1px solid var(--primary-color)",
                  }}
                >
                  <Typography style={{ fontSize: "15px", color: "#222222" }}>
                    Showing Results {page} - {pageCount}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Stack spacing={2}>
                      <Pagination
                        size="small"
                        count={pageCount}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </Stack>
                  </ThemeProvider>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                marginInline: "auto",
              }}
            >
              <Box
                style={{
                  width: "50%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: "100%",
                    objectFit: "center",
                  }}
                />
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default AllLicense;
