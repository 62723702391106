import { Box, useAutocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import { styled } from "@mui/system";
import secureLocalStorage from "react-secure-storage";
import Success from "../../../images/undraw/undraw_completed_tasks_vs6q.svg";
import Invalid from "../../../images/undraw/undraw_warning_re_eoyh.svg";

// const Label = styled("label")({
//   display: "block",
// });

const Listbox = styled("ul")(({ theme }) => ({
  width: 260,
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  overflow: "auto",
  height: 200,
  // overflowY: "auto",
  border: "1px solid rgba(0,0,0,.25)",
  "& li.Mui-focused": {
    backgroundColor: "var(--secondary-color)",
    color: "white",
    cursor: "pointer",
  },
  "& li:active": {
    backgroundColor: "var(--secondary-color)",
    color: "white",
  },
}));

const Input = styled("input")(({ theme }) => ({
  width: 200,
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#000",
  color: theme.palette.mode === "light" ? "#000" : "#fff",
}));
const SingleAgent = () => {
  const user = secureLocalStorage.getItem("admin-info");
  const agentID = user?.user?.agentId;
  const [agentData, setAgentData] = useState([]);
  const [subAgentId, setSubAgentId] = useState("");
  const [amountType, setAmountType] = useState("percentage");
  const [amount, setAmount] = useState("7");

  useEffect(() => {
    fetch(
      `https://api.flyjatt.com/v.1.0.0/WhiteLabel/SubAgent/all.php?agentId=${agentID}&all`
    )
      .then((res) => res.json())
      .then((data) => {
        setAgentData(data);
      });
  }, []);

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete-demo",
    options: agentData,
    getOptionLabel: (option) => option.name,
  });

  // markup data post

  const handleSubmit = async () => {
    const url = `https://api.flyjatt.com/v.1.0.0/WhiteLabel/SubAgent/markup.php`;
    const body = {
      method: "POST",
      "application-type": "application/json",
      body: JSON.stringify({
        agentId: agentID,
        subagentId: subAgentId,
        markup: amount,
        markuptype: amountType,
      }),
    };
    // console.log(url);
    // console.log(body);
    await fetch(url, body)
      .then((res) => res.json())
      .then((data) => {
        if (data.status.toLowerCase() === "success") {
          Swal.fire({
            imageUrl: Success,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: data.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            window.location.reload(true);
          });
        } else {
          Swal.fire({
            imageUrl: Invalid,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: data.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            window.location.reload(true);
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: err.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          caches
            .keys()
            .then((keyList) =>
              Promise.all(keyList.map((key) => caches.delete(key)))
            );
          window.location.reload(true);
        });
      });
  };

  return (
    <Box>
      <Box className="markupBox">
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <Typography
              style={{ color: "var(--primary-color)", fontSize: "14px" }}
            >
              Type Agency Name
            </Typography>
            {/* <input type="text" placeholder="Agency Name" /> */}
            <div>
              <div {...getRootProps()}>
                {/* <Label {...getInputLabelProps()}>useAutocomplete</Label> */}
                <Input
                  required
                  {...getInputProps()}
                  placeholder="Search Agent Name"
                />
              </div>
              {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()} className="listBox">
                  {groupedOptions.map((option, index) => (
                    <li {...getOptionProps({ option, index })}>
                      <p onClick={() => setSubAgentId(option.subagentId)}>
                        {option.name}
                        {option.subagentId}
                      </p>
                    </li>
                  ))}
                </Listbox>
              ) : null}
            </div>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Typography
              style={{ color: "var(--primary-color)", fontSize: "14px" }}
            >
              Select Markup Type
            </Typography>
            <select
              name="markup"
              id="markup"
              onChange={(e) => setAmountType(e.target.value)}
            >
              <option value="percent">Percentage</option>
              <option value="amount">Amount</option>
            </select>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Typography
              style={{ color: "var(--primary-color)", fontSize: "14px" }}
            >
              Enter Markup in {amountType}
            </Typography>

            <input
              type="number"
              placeholder="Enter amount or percentage"
              min="0"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12} display="flex" alignItems="flex-end">
            <Box>
              {" "}
              <button
                style={{
                  width: "250px",
                  height: "30px",
                  border: "none",
                  background: "var(--secondary-color)",
                  color: "var(--white)",
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
                onClick={handleSubmit}
              >
                Set Markup
              </button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Box mt={6}>
        {" "}
        <button
          style={{
            width: "280px",
            height: "28px",
            border: "none",
            background: "var(--mateBlack)",
            color: "var(--white)",
            cursor: "pointer",
          }}
          type="submit"
        >
          Reset Markup
        </button>
      </Box> */}
    </Box>
  );
};

export default SingleAgent;
