import {
  Box,
  Container,
  createTheme,
  Fade,
  Grid,
  Modal,
  Pagination,
  Stack,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { AdminHeader } from "../../AdminHeader/AdminHeader";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";
import Success from "../../../images/undraw/undraw_completed_tasks_vs6q.svg";
import ServerDown from "../../../images/undraw/undraw_server_down_s-4-lk.svg";
import ReConfirm from "../../../images/undraw/undraw_confirmation_re_b6q5.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AdminBankAccount = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem("admin-info");
  const [updating, setUpdating] = useState(false);
  const [updateBank, setUpdateBank] = useState({});
  const [editBank, setEditBank] = useState(false);
  const handleOpen = (data) => {
    setUpdateBank(data);
    setEditBank(true);
  };
  const handleClose = () => setEditBank(false);
  let agentId = users?.user?.agentId;
  const [bankData, setBankData] = useState([]);
  const [allBankData, setAllBankData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsloading] = useState(false);

  let size = 20;
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setBankData(allBankData.slice((value - 1) * size, value * size));
  };

  const handleUpdateData = (e) => {
    setUpdateBank({
      ...updateBank,
      [e.target.name]: e.target.value,
    });
  };
  const handleBank = async (e) => {
    e.preventDefault();
    setUpdating(true);

    let url = "https://api.flyjatt.com/v1/Bank/index.php?edit";
    let body = JSON.stringify({
      id: updateBank?.id,
      bankName: updateBank?.bankName || updateBank?.bank_name,
      accountName: updateBank?.accountName || updateBank?.account,
      IBAN: updateBank?.IBAN,
    });
    let config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    };
    // console.log(body);
    await fetch(url, config)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data?.status?.toLowerCase() === "success") {
          setEditBank(false);
          Swal.fire({
            imageUrl: Success,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: `Bank Details Updated!`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(() => {
            setUpdating(false);
            window.location.reload();
          });
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        console.log(err.message);
        setEditBank(false);
        Swal.fire({
          imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Failed!",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Another Time",
        }).then(() => {
          setUpdating(false);
          window.location.reload();
        });
      });
  };
  const bankAccountDelete = (id) => {
    const url = `https://api.flyjatt.com/v1/Bank/index.php?id=${id}&delete`;
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "You Wants to Delete Bank Details?",
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: "Delete",
      cancelButtonColor: "var(--primary-color)",
      cancelButtonText: "Cancel",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            if (data?.status?.toLowerCase() === "success") {
              Swal.fire({
                imageUrl: Success,
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `Bank Deleted!`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(() => {
                window.location.reload();
              });
            } else {
              throw new Error("error");
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              imageUrl: ServerDown,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Failed!",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Try Another Time",
            }).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };
  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/Bank/index.php?all`)
      .then((res) => res.json())
      .then((data) => {
        setBankData(data);
        setAllBankData(data);
        setPageCount(Math.ceil(data?.length / size));
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          // imageUrl: ServerDown,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Server Error",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          navigate(0);
        });
      });
  }, []);
  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "24px",
              color: "#222222",
              fontFamily: "poppins",
            }}
          >
            Bank Account
          </Typography>

          <Box sx={{ display: "flex", gap: "5px" }}>
            <Box
              sx={{
                background: "var(--primary-color)",
                height: "35px",
                borderRadius: "20px",
                input: {
                  border: "none",
                  background: "none",
                  outline: "none",
                  float: "left",
                  padding: 0,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "normal",
                  transition: "all 0.4s ease-in-out",
                  lineHeight: "35px",
                  width: "0px",
                },
                "input::placeholder": { color: "white" },
                "&:hover input": {
                  width: "235px",
                  padding: "0 10px",
                },
                "&:hover button": {
                  background: "white",
                  color: "black",
                },
                button: {
                  border: "none",
                  color: "white",
                  float: "right",
                  minHeight: "fit-content",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  background: "var(--primary-color)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "all 0.4s ease-in-out",
                  cursor: "pointer",
                  textDecoration: "none",
                },
              }}
            >
              <input
                type="text"
                placeholder="Search....."
                // onChange={handelSearchItems}
              />
              <button>
                <SearchIcon />
              </button>
            </Box>
            <Button
              style={{
                padding: "5px 10px",
                color: "var(--white)",
                background: "var(--secondary-color)",
                border: "none",
                cursor: "pointer",
                borderRadius: "4px",
                textTransform: "capitalize",
                fontSize: "14px",
              }}
              onClick={() => {
                navigate("/admin/addbank");
              }}
            >
              Add Bank
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mt: "5px",
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "0px",
              background: "var(--secondary-color)",
            },
            height: "calc(100vh - 180px)",
            overflowY: "scroll",
            table: {
              width: "100%",
              position: "relative",
              borderCollapse: "collapse",
            },
            th: {
              position: "sticky",
              top: 0,
              zIndex: "999",
              background: "var(--primary-color)",
              color: "white",
              border: "1px solid #ddd",
              padding: "8px 12px",
              fontSize: "14px",
              fontWeight: "500",
            },
            td: {
              textAlign: "center",
              verticalAlign: "middle",
              border: "1px solid #ddd !important",
              padding: "5px !important",
              fontSize: "12px",
            },
            "tr:nth-child(even)": {
              background: "#f2f2f2",
            },
            "tr:hover": {
              background: "#ddd",
            },
          }}
        >
          <table>
            <tr>
              <th>BankName</th>
              <th>AccountName</th>
              <th>IBAN</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>

            <tbody>
              {bankData?.map((data, index) => (
                <tr key={index}>
                  <td>{data?.bank_name || "Bank Name"}</td>
                  <td>{data?.account_name || "Account Name"}</td>
                  <td>{data?.IBAN || "IBAN"}</td>
                  <td>{data?.created_at || "created Date"}</td>
                  <td>
                    <EditIcon
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "22px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpen(data)}
                    />
                    <DeleteIcon
                      style={{
                        color: "tomato",
                        fontSize: "22px",
                        cursor: "pointer",
                      }}
                      onClick={() => bankAccountDelete(data?.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <Box
          sx={{
            width: "100%",
            my: 1,
            py: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "8px",
            borderTop: "1px solid var(--primary-color)",
          }}
        >
          <Typography style={{ fontSize: "15px", color: "#222222" }}>
            Showing Results {page} - {pageCount}
          </Typography>
          <ThemeProvider theme={theme}>
            <Stack spacing={2}>
              <Pagination
                size="small"
                count={pageCount}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </ThemeProvider>
        </Box>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editBank}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editBank}>
          <Box sx={style}>
            <Box mt={2}>
              <Typography
                sx={{
                  fontWeight: "500px",
                  fontSize: "23px",
                  color: "#003566",
                  textAlign: "center",
                }}
                mb={2}
              >
                Update Account
              </Typography>

              <form onSubmit={handleBank}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={2} md={6}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        Bank Name
                      </Typography>
                      <input
                        required
                        type="text"
                        name="bankName"
                        value={
                          updateBank?.bankName || updateBank?.bank_name || ""
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                        placeholder="Bank Name"
                        onChange={handleUpdateData}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={6}>
                    <Box>
                      <Typography
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                        mb={1}
                      >
                        Account Name
                      </Typography>
                      <input
                        required
                        type="text"
                        name="accountName"
                        value={
                          updateBank?.accountName ||
                          updateBank?.account_name ||
                          ""
                        }
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                        placeholder="Account Name"
                        onChange={handleUpdateData}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} md={6}>
                    <Box>
                      <Typography
                        mb={1}
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        IBAN
                      </Typography>
                      <input
                        required
                        type="text"
                        name="IBAN"
                        value={updateBank?.IBAN || ""}
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--black)",
                          width: "100%",
                          outline: "none",
                        }}
                        placeholder="IBAN"
                        onChange={handleUpdateData}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Button
                  disabled={updating ? true : false}
                  sx={{
                    background: "var(--mateBlack)",
                    color: "#FFFFFF",
                    width: "370px",
                    mt: "4rem",
                    "&:hover": {
                      backgroundColor: "var(--mateBlack)",
                    },
                  }}
                  type="submit"
                >
                  {updating ? "Wait..." : "Update Bank Account"}
                </Button>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AdminBankAccount;
