import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Fade,
  Grid,
  Modal,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../../images/loader/Render.gif";
import { useEffect } from "react";
import { AdminHeader } from "../AdminHeader/AdminHeader";
import SearchIcon from "@mui/icons-material/Search";
import Success from "../../images/undraw/undraw_completed_tasks_vs6q.svg";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import ReConfirm from "../../images/undraw/undraw_confirmation_re_b6q5.svg";
import { useQuery } from "react-query";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  outline: "none",
};

const AirlineMarkup = () => {
  const navigate = useNavigate();
  const [updating, setUpdating] = useState(false);
  const [code, setCode] = useState();
  const [updateMarkup, setUpdateMarkup] = useState({});
  const [editMarkup, setEditMarkup] = useState(false);
  const handleOpen = (code) => {
    setCode(code);
    setEditMarkup(true);
  };
  const handleClose = () => setEditMarkup(false);
  const [search, setSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [markup, setMarkup] = useState([]);
  const [allMarkup, setAllMarkup] = useState([]);
  // todo: pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 20;
  const [markupData, setMarupData] = useState({});
  const handleMarkupData = (e) => {
    setMarupData({ ...markupData, [e.target.name]: e.target.value });
  };

  const {
    isLoading: isLoadingData1,
    isError: isErrorData1,
    data: data1,
    refetch: refetchData1,
  } = useQuery(["data1", page], async () => {
    const response = await fetch(
      `https://api.flyjatt.com/v1/AirMaterials/Airlines.php?all`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data1");
    }
    return response.json();
  });

  useEffect(() => {
    setAllMarkup(data1);
    setMarkup(data1);
    // setPageCount(Math.ceil(data1?.length / size));
    refetchData1();
  }, [page, data1]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setSearch(allMarkup.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleUpdateData = (e) => {
    setUpdateMarkup({
      ...updateMarkup,
      [e.target.name]: e.target.value,
    });
  };

  const handleMarkup = (e) => {
    e.preventDefault();
    const url = `https://api.flyjatt.com/v1/MarkUp/index.php?airlines`;
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        markuptype: markupData?.markupType,
        markup: markupData?.amount,
        code: code,
      }),
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => {
        setEditMarkup(false);
        if (data.status === "success") {
          setEditMarkup(false);
          Swal.fire({
            icon: "success",
            title: data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            refetchData1();
          });
        } else {
          setEditMarkup(false);
          Swal.fire({
            icon: "error",
            title: data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          });
        }
      })
      .catch((err) => {
        setEditMarkup(false);
        console.log(err.message);
        Swal.fire({
          icon: "error",
          title: "Markup Failed!",
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          refetchData1();
        });
      });
  };

  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = allMarkup.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setMarkup(filterData);
    } else if (searchInput === "") {
      setMarkup(allMarkup);
    }
  };
  return (
    <Box sx={{ mt: "10px" }}>
      {/* <AdminHeader /> */}
      {/* <Container> */}
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "24px",
              color: "var(--mateBlack)",
            }}
          >
            Airline Markup
          </Typography>

          <Box sx={{ display: "flex", gap: "5px" }}>
            <Box
              sx={{
                background: "var(--primary-color)",
                height: "35px",
                borderRadius: "20px",
                input: {
                  border: "none",
                  background: "none",
                  outline: "none",
                  float: "left",
                  padding: 0,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "normal",
                  transition: "all 0.4s ease-in-out",
                  lineHeight: "35px",
                  width: "0px",
                },
                "input::placeholder": { color: "white" },
                "&:hover input": {
                  width: "235px",
                  padding: "0 10px",
                },
                "&:hover button": {
                  background: "white",
                  color: "black",
                },
                button: {
                  border: "none",
                  color: "white",
                  float: "right",
                  minHeight: "fit-content",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  background: "var(--primary-color)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transition: "all 0.4s ease-in-out",
                  cursor: "pointer",
                  textDecoration: "none",
                },
              }}
            >
              <input
                type="text"
                placeholder="Search....."
                onChange={handelSearchItems}
              />
              <button>
                <SearchIcon />
              </button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: "5px",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "0px",
              background: "var(--secondary-color)",
            },
            height: "calc(100vh - 180px)",
            overflowY: "scroll",
            table: {
              width: "100%",
              position: "relative",
              borderCollapse: "collapse",
            },
            th: {
              position: "sticky",
              top: 0,
              zIndex: "999",
              background: "var(--primary-color)",
              color: "white",
              border: "1px solid #ddd",
              padding: "8px 12px",
              fontSize: "14px",
              fontWeight: "500",
            },
            td: {
              textAlign: "center",
              verticalAlign: "middle",
              border: "1px solid #ddd !important",
              padding: "5px !important",
              fontSize: "12px",
            },
            "tr:nth-child(even)": {
              background: "#f2f2f2",
            },
            "tr:hover": {
              background: "#ddd",
            },
          }}
        >
          {!isLoadingData1 ? (
            <table>
              <tr>
                <th>Name</th>
                <th>Code</th>
                <th>MarkupType</th>
                <th>Markup</th>
                <th>RequireDocs</th>
                <th>Action</th>
              </tr>

              <tbody>
                {markup?.map((data, index) => (
                  <tr key={index}>
                    <td>{data?.name || "Name"}</td>
                    <td>{data?.code || "Code"}</td>
                    <td>{data?.markuptype || "Type"}</td>
                    <td>{data?.markup || 0}</td>
                    <td>{data?.requireDocs || "Doc"}</td>
                    <td>
                      <Typography
                        sx={{
                          color: "#fff",
                          bgcolor: "var(--primary-color)",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpen(data?.code)}
                      >
                        Update&nbsp;Markup
                      </Typography>

                      {/* <DeleteIcon
                        style={{
                          color: "tomato",
                          fontSize: "22px",
                          cursor: "pointer",
                        }}
                        onClick={() => bankAccountDelete(data?.id)}
                      /> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                marginInline: "auto",
              }}
            >
              <Box
                style={{
                  width: "50%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: "100%",
                    objectFit: "center",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        {/* <Box
          sx={{
            width: "100%",
            my: 1,
            py: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "8px",
            borderTop: "1px solid var(--primary-color)",
          }}
        >
          <Typography style={{ fontSize: "15px", color: "#222222" }}>
            Showing Results {page} - {pageCount}
          </Typography>
          <ThemeProvider theme={theme}>
            <Stack spacing={2}>
              <Pagination
                size="small"
                count={pageCount}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </ThemeProvider>
        </Box> */}
      </Box>
      {/* </Container> */}
      <Modal
        open={editMarkup}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editMarkup}>
          <Box sx={style} width={{ xs: "100%", sm: "80%", md: "50%" }}>
            <Box py={4}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "23px",
                  color: "#003566",
                  textAlign: "center",
                }}
                mb={5}
              >
                Update Markup
              </Typography>

              <form onSubmit={handleMarkup}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <select
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      }}
                      name="markupType"
                      value={markupData?.markupType || ""}
                      onChange={handleMarkupData}
                    >
                      <option value="">Markup Type</option>
                      <option value="amount">Amount</option>
                      <option value="percent">Percent(%)</option>
                    </select>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <input
                      name="amount"
                      value={markupData?.amount || ""}
                      type="number"
                      placeholder="Amount"
                      required
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        outline: "none",
                        border: "1px solid var(--secondary-color)",
                        borderRadius: "4px !important",
                      }}
                      onChange={handleMarkupData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <button
                      type="submit"
                      style={{
                        width: "100%",
                        background: "var(--secondary-color)",
                        color: "white",
                        outline: "none",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        border: "1px solid var(--secondary-color)",
                      }}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AirlineMarkup;
