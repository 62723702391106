import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Box,
  Grid,
  Modal,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
} from "@mui/material";
import commaNumber from "comma-number";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../images/loader/Render.gif";
import ReConfirm from "../../images/undraw/undraw_confirmation_re_b6q5.svg";
import Swal from "sweetalert2";
import { format } from "date-fns";
import "./Queuedetails.css";
import { AdminHeader } from "../AdminHeader/AdminHeader";
import secureLocalStorage from "react-secure-storage";
import FareRules from "./FareRules";
import AllPDF from "./../ALLPDF/ALLPDF";
import AirlineNames from "../AirlineNames/AirlineNames";
import Address from "../Address/Address";

const styles = {
  button: {
    padding: "6px 20px",
    marginRight: "20px",
    color: "#fff",
    backgroundColor: "var(--primary-color)",
    border: "none",
    cursor: "pointer",
  },
  buttonDisabled: {
    padding: "6px 20px",
    marginRight: "20px",
    color: "#fff",
    backgroundColor: "var(--primary-color)",
    border: "none",
    cursor: "not-allowed",
  },
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Queuedetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [downExpanded, setDownExpanded] = useState("panel1");
  const handleChangeDown = (panel) => (event, newExpanded) => {
    setDownExpanded(newExpanded ? panel : false);
  };
  const {
    BookingId: bookingId,
    AgentId: agentId,
    staffId,
    email,
    companyname,
    companyphone,
  } = location?.state.data;
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [issueLoading, setIssueLoading] = useState(false);
  const [openModalReIssue, setOpenModalReIssue] = useState(false);
  const [openModalRefund, setOpenModalRefund] = useState(false);
  const [openModalVoid, setOpenModalVoid] = useState(false);
  const users = secureLocalStorage.getItem("admin-info");
  let agentID = users?.user?.agentId;
  let actionBy = users?.user?.name;
  let staffName = users?.user?.name;
  let userStaffId = users?.user?.staffId;
  const [isDone, setIsDone] = useState(true);
  const [allData, setAllData] = useState([]);
  const [airRetrive, setAirRetrive] = useState({});
  const [passengerData, setPassengerData] = useState([]);
  const [invoiceId, setInvoiceId] = useState([]);
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState(format(new Date(), "dd-MMM-yyyy"));
  //todo:state change
  const [state, setState] = useState(false);
  // todo:for multicity
  const [multiCityAllData, setMultiCityAllData] = useState([]);
  //todo: calculate total flight duration
  const calDuration = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };
  // !end

  let count = 0;
  const passengerCount = (p) => {
    count = count + p;
    return count;
  };
  const [lastBalance, setLastBalance] = useState(0);
  // const toWords = new ToWords();
  const voideDate = new Date(allData?.lastUpdated);
  const todaydate = new Date();
  const { pnr, gds } = location.state;

  // Qoutation Satat start
  // all Modal State Declare
  const [modalOpen, setModalOpen] = useState({
    cIssue: false,
    cAllIissue: false,
    value: "",
    cancel: "",
    quotation: "",
  });

  const [isDisabled, setIsDisabled] = useState(false);

  // al Action buton state start ----
  const [stateChange, setStateChange] = useState(false);
  const [passengerDetails, setPassengerDetails] = useState([]);

  // All Action State Modal start

  const [remark, setRemark] = useState("");
  const [penalty, setPenalty] = useState(0);

  // all Action buton state end ----
  // Qoutation Satat end

  // --------------------- client information start ---------------------------

  // visa and passport copy update state
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const handleOpenUpdateModal = () => setOpenUpdateModal(true);
  const handleCloseUpdateModal = () => {
    setState((prev) => !prev);
    setOpenUpdateModal(false);
    // navigate(0);
  };
  const updateModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "#fff",
    p: 4,
    borderRadius: "10px",
  };
  const tripType = location?.state.tripType;
  const body = {
    agentId,
    bookingId,
  };
  const [flightData, setFlightData] = useState([]);
  const [fareTotal, setFareTotal] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [timeLimit, setTimeLimit] = useState([]);
  const [qeuesData, setQeuesData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const url = `https://api.flyjatt.com/v1/AirBooking/AirRetrive.php?bookingId=${bookingId}`;
  const url1 = `https://api.flyjatt.com/v1/Queues/index.php?bookingId=${bookingId}`;
  // End of visa and passport copy update
  useEffect(() => {
    // setIsLoading(true);
    fetch(url1)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        setStateData(data[0]);
        setQeuesData(data);
        setPassengerData(data?.passenger);
      });

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        if (tripType === "multicity") {
          setIsLoading(true);
          setFlightData(data?.flights);
          setFareTotal(data?.fares);
          setAllData(data);
          // Only String Check Regex
          const regex = /^[a-zA-Z\s.,!?']+$/;

          let state = data?.specialServices?.filter((oths) => {
            return oths.code === "OTHS" && !regex.test(oths.message);
          });
          setTimeLimit(state);
        } else if (tripType === "return") {
          setIsLoading(true);
          // setPassengerData(data.travelers);
          setFlightData(data?.flights);
          setFareTotal(data?.fares);
          setAllData(data);
          // Only String Check Regex
          const regex = /^[a-zA-Z\s.,!?']+$/;

          let state = data?.specialServices?.filter((oths) => {
            return oths.code === "OTHS" && !regex.test(oths.message);
          });
          setTimeLimit(state);
        } else {
          setIsLoading(true);
          // setPassengerData(data.travelers);
          setFlightData(data?.flights);
          setFareTotal(data?.fares);
          setAllData(data);
          // Only String Check Regex
          const regex = /^[a-zA-Z\s.,!?']+$/;

          let state = data?.specialServices?.filter((oths) => {
            return oths.code === "OTHS" && !regex.test(oths.message);
          });
          setTimeLimit(state);
        }
        // setInvoiceId(data[0]?.ticketData);
      });

    setIsLoading(false);
  }, [bookingId, agentId, refetch, stateData?.Status]);

  const handleMakeTicketed = (bookingId) => {
    const url = `https://api.flyjatt.com/v1/AirBooking/AirTicket.php`;
    const body = JSON.stringify({
      bookingId: bookingId,
      agentId: agentId,
    });
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          Swal.fire({
            title: "Ticketed Successful!",
            html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
            confirmButtonText: "OK",
            cancelButtonColor: "var(--primary-color)",
          }).then(() => {
            setIsDone(true);
            setRefetch(true);
          });
        } else {
          throw new Error("error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          // imageUrl: cancelFailed,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Ticketed Failed!",
          html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
          confirmButtonText: "OK",
          cancelButtonColor: "var(--primary-color)",
        }).then(() => {
          setIsDone(true);
          navigate(0);
        });
      });
  };

  const cancelBooking = () => {
    Swal.fire({
      imageUrl: ReConfirm,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: "Are you sure?",
      text: "You Wants to Cancel this Flight ?",
      showCancelButton: true,
      confirmButtonColor: "var(--secondary-color)",
      confirmButtonText: "Yes Cancel it !",
      cancelButtonColor: "var(--primary-color)",
      cancelButtonText: "Don't Cancel it !",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        setIsDone(false);
        setOpen(false);
        setIsLoading(false);
        let url = `https://api.flyjatt.com/v1/AirBooking/AirCancel.php`;
        let body = JSON.stringify({
          bookingId: bookingId,
          agentId: agentId,
        });
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              Swal.fire({
                // icon: "success",
                // imageUrl: cancelImg,
                // imageWidth: 400,
                // imageHeight: 200,
                // imageAlt: "Custom image",
                title: "Your Flight is Cancel!",
                html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
                confirmButtonText: "OK",
                cancelButtonColor: "var(--primary-color)",
              }).then(() => {
                setIsDone(true);
                navigate("/admin/bookingroute");
              });
            } else {
              throw new Error("error");
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err.message);
            Swal.fire({
              // imageUrl: cancelFailed,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Booking Cancel Failed!",
              html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
              confirmButtonText: "OK",
              cancelButtonColor: "var(--primary-color)",
            }).then(() => {
              setIsDone(true);
              navigate("/admin/bookingroute");
            });
          });
      }
    });
  };

  let reissueApprove = {
    agentId: agentId,
    reissueId: stateData?.reissueId,
    actionBy: actionBy || "Admin",
    bookingId: bookingId,
    charge: penalty,
  };

  let refundApprove = {
    agentId: agentId,
    refundId: stateData?.refundId,
    actionBy: actionBy || "Admin",
    bookingId: bookingId,
    penalty: "0",
  };

  let voidApprove = {
    agentId: agentId,
    voidId: stateData?.voidId,
    actionBy: actionBy || "Admin",
    bookingId: bookingId,
  };

  const handleApprove = (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You Wants to ${value} Approve this Flight ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      cancelButtonColor: "red",
      confirmButtonText: "Yes, Approve it!",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        // let url = `https://api.flyjatt.com/v1/Ticketed/${value}.php?approved`;
        let url = `https://api.flyjatt.com/v1/Ticketed/${value}.php?approved`;

        let body = JSON.stringify(
          value === "Reissue"
            ? reissueApprove
            : value === "Refund"
            ? refundApprove
            : value === "Void"
            ? voidApprove
            : null
        );
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            if (data?.status === "success") {
              Swal.fire({
                icon: "success",
                title: `${data.message}`,
                html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
                confirmButtonText: "OK",
              }).then(() => {
                setStateChange(true);
                setRefetch(true);
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `${data.message}`,
                html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
                confirmButtonText: "OK",
              }).then(() => {
                setStateChange(true);
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: { err },
              html: `For any query.Please contact us at <strong>support@flyjatt.com</strong> or Call <strong>+971509517784</strong>`,
              confirmButtonText: "OK",
            }).then(() => {
              setStateChange(true);
            });
          });
      }
    });
  };

  let rejectBody = {
    agentId: agentId,
    bookingId: bookingId,
    actionBy: actionBy || "Admin",
    remarks: remark,
  };

  let quotationbody = {
    agentId: agentId,
    bookingId: bookingId,
    actionBy: actionBy || "Admin",
    amount: penalty,
    text: remark,
  };

  // All action in this function approve reject
  const handleAllAction = () => {
    // Issue Reject Api
    window.event.preventDefault();
    let issuReject =
      "https://api.flyjatt.com/v1/Admin/Ticketing/ticketingReturn.php";

    // Make all Reject Api
    let allreject = `https://api.flyjatt.com/v1/Ticketed/${modalOpen?.value}.php?${modalOpen?.cancel}`;
    // Quotation send request Api
    let reissueRefund = `https://api.flyjatt.com/v1/Ticketed/${modalOpen?.value}.php?quotationsend`;
    // Quotation Approve Api
    // https://api.flyjatt.com/v1/Ticketed/reissue.php?quotationsend
    fetch(
      `${
        modalOpen?.value === "Issue"
          ? issuReject
          : modalOpen?.cancel === "Reject"
          ? allreject
          : reissueRefund
      }`,
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: JSON.stringify(
          modalOpen?.value === "Issue"
            ? rejectBody
            : modalOpen?.cancel === "Reject"
            ? rejectBody
            : modalOpen?.quotation === "quotation"
            ? quotationbody
            : ""
        ),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setIsDisabled(true);
        if (data.status === "success") {
          setModalOpen({
            cAllIissue: false,
          });
          Swal.fire({
            icon: "success",
            title: `${data?.status}`,
            text: `${data?.message}`,
            confirmButtonText: "ok",
          }).then(() => {
            setStateChange(true);
            setRefetch(true);
          });
        } else {
          setModalOpen({
            cAllIissue: false,
          });
          Swal.fire({
            icon: "error",
            title: `${data?.status}`,
            text: `${data?.message}`,
            confirmButtonText: "ok",
          }).then(() => {
            setStateChange(true);
          });
        }
      })
      .catch((err) => {
        setModalOpen({
          cAllIissue: false,
        });
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Server Error ",
          confirmButtonText: "ok",
        }).then(() => {
          setStateChange(true);
        });
      });
    setIsDisabled(true);
  };

  // -------All Action Button end-------

  if (!isDone) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box position={"relative"}>
          {isLoading ? (
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={9.5}>
                <Box sx={{ marginTop: "20px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "fit-content",
                        background: "var(--primary-color)",
                        color: "var(--secondary-color)",
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "10px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      Booking Id: {bookingId || "BookingId"}
                    </Typography>
                    <Typography
                      sx={{
                        width: "fit-content",
                        background: "var(--primary-color)",
                        color: "var(--secondary-color)",
                        fontSize: "16px",
                        fontWeight: "500",
                        padding: "10px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      {stateData?.Status}
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography
                      sx={{
                        background: "var(--third-color)",
                        color: "var(--secondary-color)",
                        padding: "2px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      Flight Information
                    </Typography>
                    <Box className="flight-queue-detail">
                      <Box mt={2}>
                        <table width="100%">
                          <tr>
                            <th width="15%">Flight</th>
                            <th width="23%">Departure From</th>
                            <th width="23%">Arrival To</th>
                            <th width="12%">Depart At</th>
                            <th width="12%">Arrive At</th>
                            <th width="15%">Info</th>
                          </tr>

                          {flightData?.length === 0 ? (
                            <Box>Loading...</Box>
                          ) : (
                            <tbody>
                              {flightData?.map((data, index) => (
                                <tr key={index}>
                                  <td>
                                    <AirlineNames code={data.airlineCode} />{" "}
                                    {data?.airlineCode}-{data?.flightNumber}
                                    <br />
                                    {data?.aircraftTypeName}
                                  </td>
                                  <td>
                                    ({data.fromAirportCode})
                                    <Address code={data.fromAirportCode} />
                                    {data?.departureTerminalName || null}{" "}
                                    {data?.departureGate
                                      ? `Gate: ${data?.departureGate || null}`
                                      : null}
                                  </td>
                                  <td>
                                    ({data.toAirportCode})
                                    <Address code={data.toAirportCode} />
                                    {data?.arrivalTerminalName || null}{" "}
                                    {data?.arrivalGate
                                      ? `Gate: ${data?.arrivalGate || null}`
                                      : null}
                                  </td>
                                  <td>
                                    {new Date(
                                      data?.departureDate
                                    ).toLocaleString("en-GB", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}{" "}
                                    {data.departureTime?.slice(0, 5)}
                                  </td>
                                  <td>
                                    {new Date(data?.arrivalDate).toLocaleString(
                                      "en-GB",
                                      {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      }
                                    )}{" "}
                                    {data.arrivalTime?.slice(0, 5)}
                                  </td>
                                  <td>
                                    {data?.cabinTypeName}-{data?.bookingClass}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </Box>
                    </Box>
                  </Box>
                  <Box my={2}>
                    <Typography
                      sx={{
                        background: "var(--third-color)",
                        color: "var(--secondary-color)",
                        padding: "2px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      Price BreakDown
                    </Typography>
                    <Box mt={2} className="flight-queue-detail">
                      {fareTotal?.length === 0 ? (
                        <>Loading...</>
                      ) : (
                        <>
                          <Box mt={2}>
                            <Box>
                              <table>
                                <tr>
                                  <th width="20%">Passenger </th>
                                  <th width="20%">Base Fare </th>
                                  <th width="20%">Tax</th>
                                  <th width="20%">Sub-Total Fare</th>
                                  <th width="20%">Baggage</th>
                                </tr>

                                {fareTotal?.map((data, index) => (
                                  <tr>
                                    <td>
                                      {data?.pricedTravelerType === "ADT"
                                        ? "Adult"
                                        : data?.pricedTravelerType === "INF"
                                        ? "Infant"
                                        : "Child"}
                                      {" X "}
                                      {data?.travelerIndices?.length || 1}
                                    </td>
                                    <td>
                                      {parseInt(data?.totals?.subtotal) *
                                        parseInt(
                                          data?.travelerIndices?.length || 1
                                        )}{" "}
                                      SAR
                                    </td>
                                    <td>
                                      {parseInt(data?.totals?.taxes) *
                                        parseInt(
                                          data?.travelerIndices?.length || 1
                                        )}{" "}
                                      SAR
                                    </td>
                                    <td>
                                      {parseInt(data?.totals?.total) *
                                        parseInt(
                                          data?.travelerIndices?.length || 1
                                        )}{" "}
                                      SAR
                                    </td>
                                    <td>
                                      {data?.fareConstruction[0]
                                        ?.checkedBaggageAllowance
                                        ?.totalWeightInKilograms
                                        ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.totalWeightInKilograms} Kg`
                                        : null}
                                      {data?.fareConstruction[0]
                                        ?.checkedBaggageAllowance?.maximumPieces
                                        ? `${data?.fareConstruction[0]?.checkedBaggageAllowance?.maximumPieces} Peices`
                                        : null}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </Box>

                            <table className="flight-queue-detail table-data">
                              <tr>
                                <td width="20%">
                                  Total Passenger{" "}
                                  <span style={{ display: "none" }}>
                                    {fareTotal?.map((data, index) =>
                                      passengerCount(
                                        data?.travelerIndices?.length
                                      )
                                    )}
                                  </span>
                                  {count || 1}
                                </td>
                                <td width="20%">
                                  Total{" "}
                                  {allData?.payments?.flightTotals[0]?.subtotal}{" "}
                                  SAR
                                </td>
                                <td width="20%">
                                  Total{" "}
                                  {allData?.payments?.flightTotals[0]?.taxes}{" "}
                                  SAR
                                </td>
                                <td width="40%">
                                  Total Amount{" "}
                                  {allData?.payments?.flightTotals[0]?.total}{" "}
                                  SAR
                                </td>
                                {/* <td width="20%"></td> */}
                              </tr>
                            </table>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box my={3}>
                    <Typography
                      sx={{
                        background: "var(--third-color)",
                        color: "var(--secondary-color)",
                        padding: "2px 5px",
                        borderRadius: "4px",
                      }}
                    >
                      Passenger Information
                    </Typography>
                    <Box mt={2} className="queue-detail-passenger-detail">
                      <div>
                        {passengerData?.length === 0 ? (
                          <>
                            {qeuesData?.data?.travelers?.map((traveler) => (
                              <Box>
                                <Box
                                  p="3px 5px"
                                  border="1px solid #DEDEDE"
                                  borderRadius="4px"
                                  my="2px"
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  <h5
                                    style={{
                                      color: "var(--secondary-color)",
                                      fontWeight: "500",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {traveler?.givenName?.slice(
                                      traveler?.givenName?.lastIndexOf(" ")
                                    )}{" "}
                                    {traveler?.givenName?.slice(
                                      0,
                                      traveler?.givenName?.lastIndexOf(" ") ||
                                        "First Name"
                                    )}{" "}
                                    {traveler?.surname || "Last Name"}
                                  </h5>
                                </Box>

                                <Box
                                  border="1px solid #DEDEDE"
                                  borderRadius="4px"
                                  p="3px 5px"
                                  mb={2}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={4} md={2}>
                                      <h5>First Name</h5>
                                      <h6>
                                        {traveler?.givenName?.slice(
                                          0,
                                          traveler?.givenName?.lastIndexOf(
                                            " "
                                          ) || "First Name"
                                        )}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                      <h5>Last Name</h5>
                                      <h6>
                                        {traveler?.surname || "Last Name"}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                      <h5>Nationality</h5>
                                      <h6>
                                        {(traveler?.identityDocuments &&
                                          traveler?.identityDocuments[0]
                                            ?.residenceCountryCode) ||
                                          "Passport Number"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                      <h5>Date of Birth</h5>
                                      <h6>
                                        {traveler?.identityDocuments &&
                                        traveler?.identityDocuments[0]
                                          ?.birthDate
                                          ? format(
                                              new Date(
                                                traveler?.identityDocuments[0]?.birthDate
                                              ),
                                              "dd MMM yyyy"
                                            )
                                          : "Date of Birth"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                      <h5>Gender</h5>
                                      <h6>
                                        {(traveler?.identityDocuments &&
                                          traveler?.identityDocuments[0]
                                            ?.gender) ||
                                          "Gender"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                      <h5>Pax Type</h5>
                                      <h6>{traveler?.type || "Type"}</h6>
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                      <h5>Passport Number</h5>
                                      <h6>
                                        {(traveler?.identityDocuments &&
                                          traveler?.identityDocuments[0]?.documentNumber?.toUpperCase()) ||
                                          "Domestic Flight"}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={2} md={3}>
                                      <h5>Expire Date</h5>

                                      <h6>
                                        {traveler?.identityDocuments &&
                                        traveler?.identityDocuments[0]
                                          ?.expiryDate
                                          ? format(
                                              new Date(
                                                traveler?.identityDocuments[0]?.expiryDate
                                              ),
                                              "dd MMM yyyy"
                                            )
                                          : "Expire Date"}
                                      </h6>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            ))}
                          </>
                        ) : (
                          <Box>
                            {passengerData?.map((traveler, index) => (
                              <Box>
                                <Box
                                  p="3px 5px"
                                  border="1px solid #DEDEDE"
                                  borderRadius="4px"
                                  my="2px"
                                  display={"flex"}
                                  justifyContent={"space-between"}
                                  width={"100%"}
                                >
                                  <h5
                                    style={{
                                      color: "var(--secondary-color)",
                                      fontWeight: "500",
                                      fontSize: "15px",
                                    }}
                                  >
                                    {traveler?.gender === "Male" ? (
                                      <>
                                        {traveler?.gender === "Male" &&
                                        traveler?.type === "ADT" ? (
                                          <>
                                            MR {traveler?.fName}{" "}
                                            {traveler?.lName}
                                          </>
                                        ) : (
                                          <>
                                            MSTR {traveler?.fName}{" "}
                                            {traveler?.lName}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {traveler?.gender === "Female" &&
                                        traveler?.type === "ADT" ? (
                                          <>
                                            MS {traveler?.fName}{" "}
                                            {traveler?.lName}
                                          </>
                                        ) : (
                                          <>
                                            MISS {traveler?.fName}{" "}
                                            {traveler?.lName}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </h5>
                                </Box>

                                <Box
                                  border="1px solid #DEDEDE"
                                  borderRadius="4px"
                                  p="3px 5px"
                                  mb={2}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={4} md={2}>
                                      <h5>Title</h5>
                                      <h6>
                                        {traveler?.gender === "Male" ? (
                                          <>
                                            {traveler?.gender === "Male" &&
                                            traveler?.type === "ADT" ? (
                                              <>MR</>
                                            ) : (
                                              <>MSTR</>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {traveler?.gender === "Female" &&
                                            traveler?.type === "ADT" ? (
                                              <>MS</>
                                            ) : (
                                              <>MISS</>
                                            )}
                                          </>
                                        )}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                      <h5>First Name</h5>
                                      <h6>{traveler?.fName}</h6>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                      <h5>Last Name</h5>
                                      <h6>{traveler?.lName}</h6>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                      <h5>Nationality</h5>
                                      <h6>{traveler?.passNation}</h6>
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                      <h5>Date of Birth</h5>
                                      <h6>
                                        {traveler?.dob
                                          ? format(
                                              new Date(traveler?.dob),
                                              "dd MMM yyyy"
                                            )
                                          : "Date of Birth"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                      <h5>Gender</h5>
                                      <h6>{traveler?.gender}</h6>
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                      <h5>Pax Type</h5>
                                      <h6>
                                        {traveler?.type === "ADT"
                                          ? "Adult"
                                          : traveler?.type === "CNN"
                                          ? "Child"
                                          : "Infat"}
                                      </h6>
                                    </Grid>

                                    <Grid item xs={4} md={2}>
                                      <h5>Passport Number</h5>
                                      <h6>
                                        {traveler?.passNo === "" ||
                                        traveler?.passNo === null
                                          ? "Domestic Flight"
                                          : traveler?.passNo?.toUpperCase()}
                                      </h6>
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                      <h5>Passport Expire Date</h5>

                                      <h6>
                                        {traveler?.passEx === "" ||
                                        traveler?.passEx === null
                                          ? "Domestic Flight"
                                          : traveler?.passEx?.toUpperCase()}
                                      </h6>
                                    </Grid>

                                    {allData?.flightTickets ===
                                    undefined ? null : (
                                      <Grid item xs={2} md={2}>
                                        <h5>Ticket Number</h5>

                                        <h6>
                                          {
                                            allData?.flightTickets[index]
                                              ?.number
                                          }
                                        </h6>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </div>
                    </Box>
                    {/* passenger information end */}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={2.5} mt={1.5}>
                <Box>
                  {stateData?.Status === "Hold" ? (
                    <Accordion
                      expanded={downExpanded === "panel1"}
                      onChange={handleChangeDown("panel1")}
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8bh-content"
                        id="panelbh-header"
                      >
                        <Typography
                          style={{
                            color: "var(--primary-color)",
                            fontFamily: "poppins",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Time Limit
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {timeLimit?.length === 0 ? (
                          "No Time Limit"
                        ) : (
                          <>
                            {timeLimit?.map((data, index) => (
                              <Box key={index}>
                                <Typography
                                  sx={{
                                    color: "#222222",
                                    fontSize: 12,
                                    my: 1,
                                    fontWeight: 500,
                                  }}
                                >
                                  {data?.message || "No Time Limit"}
                                </Typography>
                              </Box>
                            ))}
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                </Box>
                <Box>
                  {stateData?.Status === "Issue In Processing" && (
                    <Button
                      sx={{
                        my: "5px",
                        background: "var(--secondary-color)",
                        color: "white",
                        width: "100%",
                        "&:hover": {
                          background: "var(--secondary-color)",
                          color: "white",
                        },
                      }}
                      onClick={() => handleMakeTicketed(bookingId)}
                    >
                      Make Ticketed
                    </Button>
                  )}

                  {/* -------------  */}

                  {stateData?.Status === "Issue In Processing" && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: "Issue",
                            cancel: "Reject",
                          });
                        }}
                      >
                        Cancel Issue Request
                      </Typography>
                    </Box>
                  )}

                  {stateData?.Status === "Reissue In Processing" && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: "Reissue",
                            // cancel: "approved",
                            quotation: "quotation",
                          });
                        }}
                      >
                        Make Reissue Quotation
                      </Typography>
                    </Box>
                  )}

                  {stateData?.Status === "Reissue Quotation Confirm" && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: "Reissue",
                            cancel: "Reject",
                          });
                        }}
                      >
                        Cancel Reissue Request
                      </Typography>

                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          handleApprove("Reissue");
                        }}
                      >
                        Make Reissued
                      </Typography>
                    </Box>
                  )}

                  {stateData?.Status === "Refund In Processing" && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: "Refund",
                            // cancel: "approved",
                            quotation: "quotation",
                          });
                        }}
                      >
                        Make Refund Quotation
                      </Typography>
                    </Box>
                  )}

                  {stateData?.Status === "Refund Quotation Confirm" && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          handleApprove("Refund");
                        }}
                      >
                        Make Refunded
                      </Typography>

                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: "Refund",
                            cancel: "Reject",
                          });
                        }}
                      >
                        Cancel Refund Request
                      </Typography>
                    </Box>
                  )}
                  {stateData?.Status === "Void In Processing" && (
                    <Box>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          handleApprove("Void");
                        }}
                      >
                        Make Voided
                      </Typography>
                      <Typography
                        className="issueTicket-btn"
                        sx={{
                          bgcolor: "var(--primary-color)",
                          color: "#fff",
                          my: 1,
                        }}
                        onClick={(e) => {
                          setModalOpen({
                            cAllIissue: true,
                            value: "Void",
                            cancel: "Reject",
                          });
                        }}
                      >
                        Cancel Void Request
                      </Typography>
                    </Box>
                  )}
                  {stateData?.Status === "Hold" && (
                    <Button
                      sx={{
                        my: "5px",
                        background: "var(--tomato-color)",
                        color: "white",
                        width: "100%",
                        "&:hover": {
                          background: "var(--tomato-color)",
                          color: "white",
                        },
                      }}
                      onClick={() => cancelBooking(bookingId)}
                    >
                      Cancel Flight
                    </Button>
                  )}
                  {/* <Button
                    sx={{
                      my: "5px",
                      background: "var(--secondary-color)",
                      color: "white",
                      width: "100%",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "white",
                      },
                    }}
                  >
                    SSR DOCS
                  </Button> */}
                </Box>
                <Box mt={2}>
                  <AllPDF
                    isLoading
                    tripType={tripType}
                    agentID={agentID}
                    bookingId={bookingId}
                    flightData={flightData}
                    fareTotal={fareTotal}
                    passengerData={passengerData}
                    allData={allData}
                    stateData={stateData}
                    qeuesData={qeuesData}
                  />
                </Box>
                <Box py={2}>
                  <Accordion
                    expanded={downExpanded === "panel12"}
                    onChange={handleChangeDown("panel12")}
                    style={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel8bh-content"
                      id="panelbh-header"
                    >
                      <Typography
                        style={{
                          color: "#dc143c",
                          fontFamily: "poppins",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        PNR History
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {qeuesData?.activity?.map((data, index) => (
                        <Grid container key={index}>
                          <Grid item xs={1}>
                            <Box
                              width="14px"
                              height="14px"
                              bgcolor="var(--primary-color)"
                              position="relative"
                              ml={-1}
                            ></Box>
                          </Grid>
                          <Grid item mt="-3px" xs={11}>
                            <Typography
                              sx={{
                                color: "var(--primary-color)",
                                fontSize: "16px",
                                fontWeight: 500,
                              }}
                            >
                              {data?.status}
                            </Typography>
                            <Box py={2}>
                              <Typography
                                sx={{
                                  color: "#70A5D8",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                {data?.actionBy || ""}
                                {data?.status === "Hold" ||
                                data?.status === "Issue In Processing" ||
                                data?.status === "Reissue In Processing" ||
                                data?.status === "Refund In Processing" ||
                                data?.status === "Void In Processing" ? (
                                  <>
                                    {data?.actionBy === "" ? "" : ","}{" "}
                                    {companyname || ""}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#767676",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                {new Date(data?.actionAt).toLocaleString(
                                  "en-GB",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: false,
                                  }
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "crimson",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  mt: "4px",
                                }}
                              >
                                {data?.remarks === "" ||
                                data?.remarks === " " ? (
                                  ""
                                ) : (
                                  <>Remarks: {`${data?.remarks}`}</>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={downExpanded === "panel4"}
                    onChange={handleChangeDown("panel4")}
                    style={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ",
                      margin: "0",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel8bh-content"
                      id="panelbh-header"
                      style={{ margin: "0" }}
                    >
                      <Typography
                        style={{
                          color: "#dc143c",
                          fontFamily: "poppins",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        Fare rules
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        margin: "0",
                      }}
                    >
                      {allData?.fareRules === undefined ? (
                        ""
                      ) : allData?.fareRules?.length === 0 ? (
                        "Loading Data..."
                      ) : allData?.fareRules?.length >= 1 ? (
                        <Box>
                          {allData?.fareRules?.map((data) => (
                            <FareRules data={data} />
                          ))}
                        </Box>
                      ) : (
                        "No fare rules this flight"
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
                {/* ------------------All Modal Start Hear ------------------ */}
              </Grid>
            </Grid>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                marginInline: "auto",
              }}
            >
              <Box
                style={{
                  width: "50%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: "100%",
                    objectFit: "center",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {/* Cancle ReIssue Refund Void Request Modal start */}
          <Modal
            open={modalOpen.cAllIissue}
            onClose={() =>
              setModalOpen({
                cAllIissue: false,
              })
            }
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <form onSubmit={() => handleAllAction()}>
              <Box
                sx={{ ...modalStyle, width: 500 }}
                style={{
                  border: "none",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{
                    color: "#222222",
                    fontSize: "20px",
                    fontWeight: 500,
                    mb: 1,
                    textAlign: "center",
                  }}
                >
                  {modalOpen?.cancel === "Reject"
                    ? `Cancel ${modalOpen?.value} Remarks`
                    : `Send Make Quotation ${modalOpen?.value}`}
                </Typography>
                {modalOpen?.cancel === "Reject" ? (
                  <Box className="airlinePnr1" mb={2}>
                    <input
                      required
                      style={{
                        width: "100%",
                        marginLeft: "0px",
                        marginTop: "10px",
                        border: "2px solid gray",
                        borderRadius: "5px",
                        outline: "none",
                        padding: "5px 10px",
                      }}
                      type="text"
                      placeholder="Enter remarks..."
                      name="airlinePnr"
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </Box>
                ) : (
                  <Box className="airlinePnr1" mb={2}>
                    <input
                      required
                      style={{
                        width: "60%",
                        marginLeft: "0px",
                        marginTop: "10px",
                        border: "2px solid gray",
                        borderRadius: "5px",
                        outline: "none",
                        padding: "5px 10px",
                      }}
                      type="number"
                      placeholder={
                        modalOpen?.value === "Refund"
                          ? "Enter Refund Charges..."
                          : "Enter Netcost with add charges..."
                      }
                      name="airlinePnr"
                      onChange={(e) => setPenalty(e.target.value)}
                    />
                    {modalOpen?.value === "Reissue" && (
                      <input
                        required
                        style={{
                          width: "100%",
                          marginLeft: "0px",
                          marginTop: "10px",
                          border: "2px solid gray",
                          borderRadius: "5px",
                          outline: "none",
                          padding: "5px 10px",
                        }}
                        type="text"
                        placeholder="Enter remarks..."
                        name="airlinePnr"
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    )}
                  </Box>
                )}

                <Box className="balance-transaction">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box className="saveBtn1">
                      <button
                        type="submit"
                        onClick={() => handleAllAction()}
                        disabled={isDisabled}
                        style={
                          isDisabled ? styles.buttonDisabled : styles.button
                        }
                      >
                        {isDisabled ? "Wait For Response or Reload" : "Save"}
                      </button>
                    </Box>
                    <Button
                      size="small"
                      sx={{
                        color: "#fff",
                        bgcolor: "red",
                        "&:hover": {
                          bgcolor: "red",
                        },
                      }}
                      onClick={() => setModalOpen({ cAllIissue: false })}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </form>
          </Modal>
          {/* Cancle Issue Request Modal end */}
        </Box>
      </Container>
    </Box>
  );
};

export default Queuedetails;
