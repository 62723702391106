import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Manage.css";
import Swal from "sweetalert2";
import Slider from "react-slick";
import ServerDown from "../../../images/undraw/undraw_server_down_s-4-lk.svg";
import img0 from "../../../images/TrendingDeals/img1.webp";
import img1 from "../../../images/TrendingDeals/img2.webp";
import img2 from "../../../images/TrendingDeals/img3.webp";
import { AdminHeader } from "../../AdminHeader/AdminHeader";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { styled } from "@mui/material/styles";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#222222",
    color: "rgba(0, 0, 0, 0.87)",
    minwidth: 180,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    padding: "10px",
  },
}));

const Offer = () => {
  const slider = useRef(null);

  // const settings = {
  //   arrows: true,
  //   dots: false,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   responsive: [
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const navigate = useNavigate();
  const [otherData, setotherData] = useState({});
  const [image, setImage] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState({
    btnLoading: false,
    fetchLoading: false,
    editLoading: false,
  });

  const [editData, setEditData] = useState();
  // console.log(editData);
  const handleOnChange = (e) => {
    setotherData({ ...otherData, [e.target.name]: e.target.value });
  };
  const handleOnChangeEdit = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };
  const handleOtherSubmit = async (e) => {
    setIsLoading({
      btnLoading: true,
    });
    const formData = new FormData();
    formData.append("caption", otherData.caption);
    formData.append("link", otherData.link);
    formData.append("file", image);

    e.preventDefault();
    const url = "https://api.flyjatt.com/v1/CMS/index.php?offers";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(url, formData, config)
      .then((res) => {
        if (res?.data?.status === "success") {
          setIsLoading({
            btnLoading: false,
          });
          Swal.fire({
            icon: "success",
            title: res?.data?.status,
            html: res?.data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            setIsLoading({ fetchLoading: true });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: res?.data?.status,
            html: res?.data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            setIsLoading({
              btnLoading: true,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading({
          btnLoading: true,
        });
        Swal.fire({
          icon: "error",
          title: err,
          html: err.message,
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          setIsLoading({
            btnLoading: true,
          });
        });
      });
    e.target.reset();
  };

  const handleDelete = async (e, id) => {
    setIsLoading({
      btnLoading: true,
    });

    e.preventDefault();
    const url = `https://api.flyjatt.com/v1/CMS/index.php?offerdelete&id=${id}`;

    axios
      .post(url)
      .then((res) => {
        if (res?.data?.status === "success") {
          Swal.fire({
            icon: "success",
            title: res?.data?.status,
            html: res?.data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            setIsLoading({
              fetchLoading: true,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: res?.data?.status,
            html: res?.data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            setIsLoading({
              fetchLoading: true,
            });
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading({
          btnLoading: true,
        });
        Swal.fire({
          icon: "error",
          title: err,
          html: err.message,
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          setIsLoading({
            btnLoading: true,
          });
        });
      });
    e.target.reset();
  };

  const handleEdit = async (e, id) => {
    setIsLoading({
      btnLoading: true,
    });
    let editbody = JSON.stringify({
      id: editData.id,
      caption: editData.caption,
      link: editData.link,
    });
    console.log(editbody);
    e.preventDefault();
    const url = `http://api.flyjatt.com/v1/CMS/index.php?offeredit`;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: editbody,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading({ editLoading: true });
        if (data.status === "success") {
          setModalOpen(false);
          setIsLoading({
            btnLoading: false,
          });
          Swal.fire({
            icon: "success",
            title: `${data?.status}`,
            text: `${data?.message}`,
            confirmButtonText: "ok",
          }).then(() => {
            setIsLoading({ fetchLoading: true });
          });
        } else {
          setModalOpen(false);
          Swal.fire({
            icon: "error",
            title: `${data?.status}`,
            text: `${data?.message}`,
            confirmButtonText: "ok",
          }).then(() => {
            setIsLoading({ fetchLoading: true });
          });
        }
      })
      .catch((err) => {
        setModalOpen(false);

        Swal.fire({
          icon: "error",
          title: "Server Error",
          text: "Server Error",
          confirmButtonText: "ok",
        }).then(() => {
          setIsLoading({ fetchLoading: true });
        });
      });
    e.target.reset();
  };

  useEffect(() => {
    fetch(`https://api.flyjatt.com/v1/CMS/index.php?alloffer`)
      .then((res) => res.json())
      .then((data) => {
        setAllData(data);
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading({
          fetchLoading: true,
        });
        Swal.fire({
          imageAlt: "Custom image",
          title: "Failed! to Fetch",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Try Another Time",
        }).then(() => {
          setIsLoading({
            fetchLoading: true,
          });
          navigate(-1);
        });
      });
  }, [isLoading.fetchLoading]);

  const len = allData?.length > 4 ? 4 : allData?.length;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: len,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    arrows: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box>
          <Typography
            my={2}
            style={{
              color: "var(--mateBlack)",
              fontSize: "22px",
              fontWeight: 500,
            }}
          >
            Add a list of Offers for Agents{" "}
            <span style={{ fontSize: "12px", fontWeight: 500 }}>
              *Minimum of 4 Offers Added
            </span>
          </Typography>
          <Box>
            <form onSubmit={handleOtherSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  "& input": {
                    width: "100%",
                    outline: "none",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "4px !important",
                  },
                }}
              >
                <Grid item xs={12} sm={4}>
                  <input
                    name="caption"
                    value={otherData?.caption || ""}
                    type="text"
                    placeholder="Caption..."
                    required
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      height: "42px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    onChange={handleOnChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <input
                    name="link"
                    value={otherData?.link || ""}
                    type="text"
                    pattern="^(https:\/\/).*"
                    placeholder="Link (https://example.com)"
                    required
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      height: "42px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    onChange={handleOnChange}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <input
                    name="file"
                    type="file"
                    required
                    style={{
                      width: "100%",
                      height: "42px",
                      padding: "9px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  {isLoading.btnLoading ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        bgcolor: "#222222",
                        fontWeight: 500,
                        textAlign: "center",
                        px: 1,
                      }}
                    >
                      Wait For Response or reload it
                    </Typography>
                  ) : (
                    <button
                      type="submit"
                      style={{
                        width: "100%",
                        height: "42px",

                        background: "var(--secondary-color)",
                        color: "white",
                        outline: "none",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        border: "1px solid var(--secondary-color)",
                        cursor: "pointer",
                      }}
                    >
                      Submit
                    </button>
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
        <Box
          sx={{
            margin: "50px 0px 10px",
            width: { md: "100%", sm: "75vw", xs: "90vw" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "26px",
                  color: "var(--secondary-color)",
                  fontWeight: "500",
                  paddingLeft: "5px",
                  margin: "10px 0 20px",
                }}
              >
                Trending Offers For {new Date().getFullYear()}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "5px" }}>
              <Box
                sx={{
                  border: "1px solid var(--primary-color)",
                  borderRadius: "50%",
                  background: "var(--white)",
                  color: "var(--primary-color)",
                  height: "30px",
                  width: "30px",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    color: "var(--primary-color)",
                    background: "var(--white)",
                    justifyContent: "center",
                  },
                }}
                onClick={() => slider?.current?.slickPrev()}
              >
                <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
              </Box>
              <Box
                sx={{
                  border: "1px solid var(--secondary-color)",
                  borderRadius: "50%",
                  background: "var(--secondary-color)",
                  color: "var(--white)",
                  height: "30px",
                  width: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  transition: "all .5 ease",
                  "&:hover": {
                    color: "var(--white)",
                    justifyContent: "end",
                  },
                }}
                onClick={() => slider?.current?.slickNext()}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ".slick-slide > div": {
                margin: "1px 10px",
                width: "auto",
              },
              ".slick-list": {
                margin: "0px -5px",
              },
            }}
          >
            <Slider ref={slider} {...settings}>
              {allData?.map((item, index) => (
                <Box key={index} className="offer-slider-item">
                  <Box
                    sx={{
                      display: "flex",
                      height: "350px",
                      borderRadius: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        borderRadius: "5px",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <img
                        src={`https://api.flyjatt.com/${item?.image}`}
                        alt="..."
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                      <Tooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" fontSize="15px">
                              <strang>Caption</strang>{" "}
                              {item?.caption || "nocaption"}
                            </Typography>
                            <Typography color="inherit" fontSize="13px">
                              <strang>Link:</strang>{" "}
                              <u> {item?.link || "nolink"} </u>
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <span>{item?.caption || "nocaption"}</span>
                      </Tooltip>
                      <Box
                        position="absolute"
                        sx={{
                          top: "5%",
                          left: "0",
                          px: 1,
                          py: 0,
                          background: "#fff",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            color: "red",
                            fontSize: "25px",
                            cursor: "pointer",
                            mt: 0.5,
                          }}
                          onClick={(e) => handleDelete(e, item?.id)}
                        />
                      </Box>
                      <Box
                        position="absolute"
                        sx={{
                          top: "5%",
                          right: "0",
                          px: 1,
                          py: 0,
                          background: "#fff",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                        onClick={() => {
                          setModalOpen(true);
                          setEditData(item);
                        }}
                      >
                        <NoteAltIcon
                          sx={{
                            mt: 0.5,
                            color: "red",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box
            sx={{ ...modalStyle, width: 500 }}
            style={{
              border: "none",
              borderRadius: "5px",
            }}
          >
            <Typography
              sx={{
                color: "#222222",
                fontSize: 22,
                my: 2,
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Update Offer
            </Typography>
            <Grid container mb={2}>
              <Grid item xs={12} sm={12}>
                <input
                  name="caption"
                  value={editData?.caption || ""}
                  type="text"
                  placeholder="Caption..."
                  required
                  style={{
                    width: "100%",
                    padding: "5px 10px",
                    height: "42px",
                    outline: "none",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "4px !important",
                  }}
                  onChange={handleOnChangeEdit}
                />
              </Grid>
              <Grid item xs={12} sm={12} mt={1}>
                <input
                  name="link"
                  value={editData?.link || ""}
                  type="text"
                  placeholder="Link (https://example.com)"
                  required
                  style={{
                    width: "100%",
                    padding: "5px 10px",
                    height: "42px",
                    outline: "none",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "4px !important",
                  }}
                  onChange={handleOnChangeEdit}
                />
              </Grid>
            </Grid>
            <Box className="balance-transaction">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box className="saveBtn1">
                  {isLoading.btnLoading ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        bgcolor: "#222222",
                        fontWeight: 500,
                        textAlign: "center",
                        px: 1,
                      }}
                    >
                      Wait For Response or reload it
                    </Typography>
                  ) : (
                    <button
                      type="submit"
                      style={{
                        width: "100%",

                        background: "var(--secondary-color)",
                        color: "white",
                        outline: "none",
                        padding: "5px 10px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        border: "1px solid var(--secondary-color)",
                        cursor: "pointer",
                      }}
                      onClick={handleEdit}
                    >
                      Submit
                    </button>
                  )}
                </Box>
                <Button
                  size="small"
                  sx={{
                    color: "#fff",
                    bgcolor: "red",
                    "&:hover": {
                      bgcolor: "red",
                    },
                  }}
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
          {/* </form> */}
        </Modal>
      </Container>
    </Box>
  );
};

export default Offer;
