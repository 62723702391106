import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AdminHeader } from "../../AdminHeader/AdminHeader";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AgentMarkupDetails from "./AgentMarkupDetails";
import axios from "axios";

const AgentMarkup = ({ data }) => {
  const navigate = useNavigate();
  const agentId = data?.agentId;
  const [markupData, setMarupData] = useState({});
  const [value, setValue] = useState("flight");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleMarkupData = (e) => {
    setMarupData({ ...markupData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://api.flyjatt.com/v1/MarkUp/agent.php?${value}`;
    const config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        agentId: agentId,
        markupType: markupData?.markupType,
        markup: markupData?.amount,
      }),
    };
    fetch(url, config)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          Swal.fire({
            icon: "success",
            title: data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            navigate(0);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: data?.message,
            confirmButtonText: "ok",
            confirmButtonColor: "var(--primary-color)",
          }).then(() => {
            navigate(0);
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          icon: "error",
          title: "Markup Failed!",
          confirmButtonText: "ok",
          confirmButtonColor: "var(--primary-color)",
        }).then(() => {
          navigate(0);
        });
      });
  };

  
  return (
    <Box>
      {/* <AdminHeader /> */}
      <Typography
        my={2}
        style={{
          color: "var(--mateBlack)",
          fontSize: "22px",
          fontWeight: "500",
        }}
      >
        Add Markup
      </Typography>
      <Box>
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              height: { md: "100%", sm: "100%", xs: "100%" },
              display: "flex",
              justifyContent: {
                md: "space-between",
                sm: "start",
                xs: "start",
              },
              alignItems: "center",
              opacity: "1",
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
                gap: "5px",
              },
              ".MuiTabs-root": {
                minHeight: "fit-content",
              },
              "& button": {
                opacity: "1",
                background: "var(--third-color)",
                color: "var(--secondary-color)",
                width: "fit-content",
                minHeight: "fit-content",
                padding: "10px 5px",
                fontSize: "12px",
                borderRadius: "4px",
              },
              "& button.Mui-selected,& button.Mui-selected >svg": {
                background: "var(--secondary-color)",
                color: "var(--white) !important",
              },
              "& .MuiBadge-badge": {
                fontSize: "10px",
                right: 6,
                top: 2,
              },
            }}
          >
            <TabList onChange={handleChange} color="#fff" indicatorColor="none">
              <Tab label="Flight" value="flight" />
              {/* <Tab label="Airlines" value="airlines" /> */}
              <Tab label="Refund" value="refund" />
              <Tab label="Reissue" value="reissue" />
              <Tab label="Void" value="void" />
            </TabList>
          </Box>
          {/* //todo:transaction part */}
          <TabPanel value="flight">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                // sx={{
                //   "& input": {
                //     width: "100%",
                //     outline: "none",
                //     border: "1px solid var(--secondary-color)",
                //     borderRadius: "4px !important",
                //   },
                // }}
              >
                <Grid item xs={12} sm={4} md={4}>
                  <select
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    name="markupType"
                    value={markupData?.markupType || ""}
                    onChange={handleMarkupData}
                  >
                    <option value="">Markup Type</option>
                    <option value="amount">Amount</option>
                    <option value="percent">Percent(%)</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <input
                    name="amount"
                    value={markupData?.amount || ""}
                    type="text"
                    placeholder="Amount"
                    required
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    onChange={handleMarkupData}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <button
                    type="submit"
                    style={{
                      width: "100%",
                      background: "var(--secondary-color)",
                      color: "white",
                      outline: "none",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      border: "1px solid var(--secondary-color)",
                    }}
                  >
                    Submit
                  </button>
                </Grid>
              </Grid>
            </form>
          </TabPanel>

          <TabPanel value="refund">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  "& input": {
                    width: "100%",
                    outline: "none",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "4px !important",
                  },
                }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <select
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    name="markupType"
                    value={markupData?.markupType || ""}
                    onChange={handleMarkupData}
                  >
                    <option value="">Markup Type</option>
                    <option value="amount">Amount</option>
                    <option value="percent">Percent(%)</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <input
                    name="amount"
                    value={markupData?.amount || ""}
                    type="text"
                    placeholder="Amount"
                    required
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    onChange={handleMarkupData}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <button
                    type="submit"
                    style={{
                      width: "100%",
                      background: "var(--secondary-color)",
                      color: "white",
                      outline: "none",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      border: "1px solid var(--secondary-color)",
                    }}
                  >
                    Submit
                  </button>
                </Grid>
              </Grid>
            </form>
          </TabPanel>
          <TabPanel value="reissue">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  "& input": {
                    width: "100%",
                    outline: "none",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "4px !important",
                  },
                }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <select
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    name="markupType"
                    value={markupData?.markupType || ""}
                    onChange={handleMarkupData}
                  >
                    <option value="">Markup Type</option>
                    <option value="amount">Amount</option>
                    <option value="percent">Percent(%)</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <input
                    name="amount"
                    value={markupData?.amount || ""}
                    type="text"
                    placeholder="Amount"
                    required
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    onChange={handleMarkupData}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <button
                    type="submit"
                    style={{
                      width: "100%",
                      background: "var(--secondary-color)",
                      color: "white",
                      outline: "none",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      border: "1px solid var(--secondary-color)",
                    }}
                  >
                    Submit
                  </button>
                </Grid>
              </Grid>
            </form>
          </TabPanel>
          <TabPanel value="void">
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                sx={{
                  "& input": {
                    width: "100%",
                    outline: "none",
                    border: "1px solid var(--secondary-color)",
                    borderRadius: "4px !important",
                  },
                }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <select
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    name="markupType"
                    value={markupData?.markupType || ""}
                    onChange={handleMarkupData}
                  >
                    <option value="">Markup Type</option>
                    <option value="amount">Amount</option>
                    <option value="percent">Percent(%)</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <input
                    name="amount"
                    value={markupData?.amount || ""}
                    type="text"
                    placeholder="Amount"
                    required
                    style={{
                      width: "100%",
                      padding: "5px 10px",
                      outline: "none",
                      border: "1px solid var(--secondary-color)",
                      borderRadius: "4px !important",
                    }}
                    onChange={handleMarkupData}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <button
                    type="submit"
                    style={{
                      width: "100%",
                      background: "var(--secondary-color)",
                      color: "white",
                      outline: "none",
                      padding: "5px 10px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      border: "1px solid var(--secondary-color)",
                    }}
                  >
                    Submit
                  </button>
                </Grid>
              </Grid>
            </form>
          </TabPanel>
        </TabContext>
      </Box>
      <AgentMarkupDetails markupValue={value} agentId={agentId} />
    </Box>
  );
};

export default AgentMarkup;
