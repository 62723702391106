import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AdminHeader } from "../../../AdminHeader/AdminHeader";
import Loader from "../../../../images/loader/Render.gif";
import { format } from "date-fns";

const AgentDeposit = () => {
  const location = useLocation();
  const agentId = location?.state;
  const [isLoaded, setIsLoaded] = useState(false);
  const [depositData, setDepositData] = useState([]);
  useEffect(() => {
    fetch(
      `https://api.flyjatt.com/v1/Deposit/index.php?agentId=${agentId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoaded(true);
        setDepositData(data?.data);
      });
  }, [agentId]);
  if (!isLoaded) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "30%",
            height: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }
  return (
    <div>
      <AdminHeader />
      <Container>
        <Typography
          sx={{
            mt: 2,
            mb: 1,
            color: "var(--secondary-color)",
            fontSize: "24px",
            fontWeight: "500",
          }}
        >
          Agent Deposit
        </Typography>
        <Box
          sx={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "0px",
              background: "var(--secondary-color)",
            },
            height: "calc(100vh - 210px)",
            overflowY: "scroll",
            table: {
              width: "100%",
              position: "relative",
              borderCollapse: "collapse",
            },
            th: {
              position: "sticky",
              top: 0,
              zIndex: "999",
              background: "var(--primary-color)",
              color: "white",
              border: "1px solid #ddd",
              padding: "8px 12px",
              fontSize: "14px",
              fontWeight: "500",
            },
            td: {
              textAlign: "center",
              verticalAlign: "middle",
              border: "1px solid #ddd !important",
              padding: "5px !important",
              fontSize: "12px",
            },
            "tr:nth-child(even)": {
              background: "#f2f2f2",
            },
            "tr:hover": {
              background: "#ddd",
            },
          }}
        >
          <table>
            <tr>
              <th>RefId</th>
              <th>Status</th>
              <th>Type </th>
              <th>Amount</th>
              <th>Date</th>
              <th>Attachment</th>
              <th>Remarks</th>
            </tr>

            <tbody>
              {depositData
                ? depositData?.map((data, index) => (
                    <tr key={index}>
                      <td>{data?.depositId}</td>
                      <td>
                        {data?.status ? (
                          <Typography
                            sx={{ fontSize: "12px", textAlign: "center" }}
                            className={`${data?.status
                              ?.toLowerCase()
                              ?.split(" ")
                              ?.join("-")}-btn`}
                          >
                            {data?.status}
                          </Typography>
                        ) : (
                          "Status"
                        )}
                      </td>
                      <td>{data?.paymentway}</td>
                      <td>{data?.amount}</td>
                      <td> {data?.created_at}</td>
                      <td>
                        <a
                          href={`https://api.flyjatt.com/assets/deposit/${agentId}/${data?.attachment}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "black" }}
                        >
                          View
                        </a>
                      </td>
                      <td> {data?.remarks === "" ? "N/A" : data?.remarks} </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </Box>
      </Container>
    </div>
  );
};

export default AgentDeposit;
