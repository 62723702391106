import {
  Box,
  Button,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import Success from "../../images/undraw/undraw_completed_tasks_vs6q.svg";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import JoditEditor from "jodit-react";
import { AdminHeader } from "../AdminHeader/AdminHeader";

const AddNotice = () => {
  const user = secureLocalStorage.getItem("admin-info");
  const navigate = useNavigate();
  const location = useLocation();

  const [title, setTitle] = useState(location?.state?.data?.title || "");
  const id = location?.state?.data?.id;
  const editor = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [overview, setOverview] = useState("");

  const agentId = user?.user?.agentId;

  const addedit = location?.state?.edit || "add";

  const updateData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `https://api.flyjatt.com/v1/Notice/index.php?add`;
    const body = {
      method: "POST",
      "application-type": "application/json",
      body: JSON.stringify({
        notice: overview,
      }),
    };
    await fetch(url, body)
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        if (data.status.toLowerCase() === "success") {
          Swal.fire({
            imageUrl: Success,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: data.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            navigate(-1);
          });
        } else {
          Swal.fire({
            imageUrl: Invalid,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: data.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            caches
              .keys()
              .then((keyList) =>
                Promise.all(keyList.map((key) => caches.delete(key)))
              );
            navigate(0);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        Swal.fire({
          imageUrl: Invalid,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: err.message,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          caches
            .keys()
            .then((keyList) =>
              Promise.all(keyList.map((key) => caches.delete(key)))
            );
          navigate(0);
        });
      });
  };
  return (
    <Box>
      <AdminHeader />
      <Container sx={{ pt: 4 }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "24px",
            color: "var(--mateBlack)",
          }}
        >
          Add New Notices
        </Typography>

        <form onSubmit={updateData}>
          <textarea
            required
            type="text"
            name="notice"
            rows={4}
            value={overview || ""}
            style={{
              border: "1px solid var(--black)",
              width: "100%",
              outline: "none",
            }}
            placeholder="Notice"
            onChange={(e) => setOverview(e.target.value)}
          />
          <Button
            type="submit"
            sx={{
              background: "var(--secondary-color)",
              color: "var(--white)",
              fontSize: "14px",
              "&:hover": {
                background: "var(--secondary-color)",
                color: "var(--white)",
              },
              margin: "5px 0px",
            }}
          >
            {isLoading ? (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
              "Save"
            )}
          </Button>
        </form>
      </Container>
    </Box>
  );
};

export default AddNotice;
