import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import commaNumber from "comma-number";
import Loader from "../../../../images/loader/Render.gif";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
// import "./Queues.css";
import { format } from "date-fns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdminHeader } from "../../../AdminHeader/AdminHeader";
const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});
const AgentBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const agentId = location?.state;

  const [isLoading, setIsLoading] = useState(false);
  const [queuesData, setQueuesData] = useState([]);
  const [allQueuesData, setAllQueuesData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
    fetch(
      `https://api.flyjatt.com/v1/Queues/index.php?agentId=${agentId}&page=${value}`
    )
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.number_of_page);
        setQueuesData(data?.data);
        setAllQueuesData(data?.data);
      });
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `https://api.flyjatt.com/v1/Queues/index.php?agentId=${agentId}&page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.number_of_page);
        setQueuesData(data?.data);
        setAllQueuesData(data?.data);
        setIsLoading(false);
      });
    //todo: end of fetch all data
  }, [agentId]);
  //  short functionality handle
  const handleChangeOption = (e) => {
    const status = e.target.value?.replaceAll(/\s/g, "")?.toLowerCase();
    if (status !== "all") {
      const filterData = allQueuesData.filter((item) => {
        return item?.Status?.replaceAll(/\s/g, "")?.toLowerCase() === status;
      });

      setQueuesData(filterData);
    } else {
      setQueuesData(allQueuesData);
    }
  };

  const handelSearchItems = (e) => {
    const searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = allQueuesData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setQueuesData(filterData);
    } else if (searchInput === "") {
      setQueuesData(allQueuesData);
    }
  };

  const sendToQueuesDetails = (agentId, BookingId, tripType, pnr, gds) => {
    navigate("/user/queuesdetail", {
      state: {
        agentId,
        BookingId,
        tripType,
        pnr,
        gds,
        path: "queues",
      },
    });
  };

  if (isLoading) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "100%",
              objectFit: "center",
            }}
          />
          Loading ...
        </Box>
      </Box>
    );
  }
  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box mt={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: "0px 0px 5px",
            }}
          >
            <Typography
              sx={{
                color: "var(--secondary-color)",
                fontSize: "24px",
                fontWeight: "500",
              }}
            >
              Queue Management
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  background: "var(--primary-color)",
                  height: "35px",
                  borderRadius: "20px",
                  input: {
                    border: "none",
                    background: "none",
                    outline: "none",
                    float: "left",
                    padding: 0,
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "normal",
                    transition: "all 0.4s ease-in-out",
                    lineHeight: "35px",
                    width: "0px",
                  },
                  "input::placeholder": { color: "white" },
                  "&:hover input": {
                    width: "235px",
                    padding: "0 10px",
                  },
                  "&:hover button": {
                    background: "white",
                    color: "black",
                  },
                  button: {
                    border: "none",
                    color: "white",
                    float: "right",
                    minHeight: "0px",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    background: "var(--primary-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.4s ease-in-out",
                    cursor: "pointer",
                    textDecoration: "none",
                  },
                }}
              >
                <input
                  type="text"
                  placeholder="Search....."
                  onChange={handelSearchItems}
                />
                <button>
                  <SearchIcon />
                </button>
              </Box>
              <select
                onChange={handleChangeOption}
                style={{
                  width: "150px",
                  borderRadius: "4px",
                  height: "100%",
                  padding: "5px 10px",
                  color: "var(--primary-color)",
                  fontWeight: "500",
                  borderColor: "var(--secondary-color)",
                }}
              >
                <option value="all">&nbsp;Show All</option>
                <option value="hold">&nbsp;Hold</option>
                <option value="Issue In Processing">
                  &nbsp;Issue In Processing
                </option>
                <option value="Ticketed">&nbsp;Ticketed</option>

                <option value="Void In Processing">
                  &nbsp;Void In Processing
                </option>
                <option value="Voided">&nbsp;Voided</option>
                <option value="Void Rejected">&nbsp;Void Rejected</option>

                <option value="Reissue In Processing">
                  &nbsp;Reissue In Processing
                </option>
                <option value="Reissued">&nbsp;Reissued</option>
                <option value="Reissue Rejected">&nbsp;Reissue Rejected</option>

                <option value="Refund In Processing">
                  &nbsp;Refund In Processing
                </option>
                <option value="Refunded">&nbsp;Refunded</option>
                <option value="Refund Rejected">&nbsp;Refund Rejected</option>
                <option value="Issue Rejected">&nbsp;Issue Rejected</option>
                <option value="cancelled">&nbsp;Cancelled</option>
              </select>
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "0px",
                  background: "var(--secondary-color)",
                },
                height: "calc(100vh - 230px)",
                overflowY: "scroll",
                table: {
                  width: "100%",
                  position: "relative",
                  borderCollapse: "collapse",
                },
                th: {
                  position: "sticky",
                  top: 0,
                  zIndex: "999",
                  background: "var(--primary-color)",
                  color: "white",
                  border: "1px solid #ddd",
                  padding: "8px 12px",
                  fontSize: "14px",
                  fontWeight: "500",
                },
                td: {
                  textAlign: "center",
                  verticalAlign: "middle",
                  border: "1px solid #ddd !important",
                  padding: "5px !important",
                  fontSize: "12px",
                },
                "tr:nth-child(even)": {
                  background: "#f2f2f2",
                },
                "tr:hover": {
                  background: "#ddd",
                },
              }}
            >
              <table>
                <thead>
                  <tr>
                    <th>ReferenceId</th>
                    <th>Airlines</th>
                    <th>Status</th>
                    <th>Route</th>
                    <th>Type</th>
                    <th>Pax</th>
                    <th>BookedDate</th>
                    <th>FlightDate</th>
                    <th>Passenger</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {queuesData?.map((bookingDetail, index) => (
                    <tr key={{ index }}>
                      <td>
                        <Button
                          disabled={
                            bookingDetail.Status === "Cancelled" ? true : false
                          }
                          sx={{
                            border: "none",
                            cursor: "pointer",
                            background: "#d1e9ff",
                            color: "var(--secondary-color)",
                            padding: "5px 10px",
                            textDecoration: "underline",
                            borderRadius: "4px",
                            fontSize: "12px",
                            "&:hover": {
                              background: "#d1e9ff",
                              color: "#003566",
                            },
                          }}
                          onClick={() =>
                            sendToQueuesDetails(
                              bookingDetail?.AgentId,
                              bookingDetail?.BookingId,
                              bookingDetail?.TripType,
                              bookingDetail?.Pnr,
                              bookingDetail?.gdsSystem
                            )
                          }
                        >
                          {bookingDetail.BookingId ?? "Reference No"}
                        </Button>
                      </td>
                      <td>{bookingDetail.Career ?? "Airlines"}</td>
                      <td>
                        <Typography
                          sx={{ fontSize: "12px", textAlign: "center" }}
                          className={`${bookingDetail.Status?.toLowerCase()
                            ?.split(" ")
                            ?.join("-")}-btn`}
                        >
                          {bookingDetail.Status}
                        </Typography>
                      </td>
                      <td>
                        {bookingDetail.DepFrom ?? "From"} -{" "}
                        {bookingDetail.ArrTo ?? "To"}
                      </td>
                      <td>{bookingDetail.TripType ?? "Flight Type"}</td>
                      <td>{bookingDetail.TotalPax ?? "Total Pax"}</td>

                      <td>
                        {bookingDetail?.created_at !== "" || "undefined" || null
                          ? format(
                              new Date(bookingDetail?.created_at),
                              "dd MMM yy hh:mm a"
                            )
                          : "Booked Date"}
                      </td>
                      <td>{bookingDetail?.journeyDate || "Flight Date"}</td>
                      <td>{bookingDetail?.Name || "Passenger Name"}</td>
                      <td>
                        {commaNumber(bookingDetail.InvoicePrice) + "SAR" ||
                          "Gross Cost"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <Box
              sx={{
                width: "100%",
                py: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <Typography style={{ fontSize: "15px", color: "#222222" }}>
                    Showing Results 1 - 10 of 20
                  </Typography> */}
              <ThemeProvider theme={theme}>
                <Stack spacing={2}>
                  <Pagination
                    size="small"
                    count={pageCount}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </ThemeProvider>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AgentBooking;
